<template>
  <div class="user-info-page">
    <div class="user-item-container">
      <UserInfoDisplay
        :name="user.worker_name"
        :photo="user.worker_image"
        :progress="user.process"
        :progressColor="'#ec5b56'"
        :rate="user.worker_rate"
        :tags="user.tags"
        :waitTime="user.estimated_wait_time"
      />
      <p class="status-text" :style="{ color: user.textColor }">{{ user.statusText }}</p>
    </div>
    <div class="user-steps">
      <van-steps :active="activeStep" active-color="#ec5b56">
        <van-step>等待</van-step>
        <van-step>预检</van-step>
        <van-step>检查</van-step>
        <van-step>维修</van-step>
        <van-step>取车</van-step>
        <van-step>评价</van-step>
      </van-steps>
    </div>

    <div style="margin-top: 2vh" class="user-steps">
      <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-item="onServiceSelect"
      />
    </div>

    <div style="margin-top: 2vh" class="user-steps">
      <van-field
        size="normal"
        :colon="true"
        v-model="questionDescription"
        rows="1"
        autosize
        label="问题描述"
        type="textarea"
        placeholder="请输入详细的问题描述"
      />
    </div>

    <van-button
      color="#ec5b56"
      block
      style="margin: 2vh auto 0;display:block;border-radius: 15px"
      type="primary"
      @click="handleSubmit"
    >
      点击提交
    </van-button>
     <van-button
      color="#ec5b56"
      block
      plain
      style="margin: 2vh auto 0;display:block;border-radius: 15px"
      @click="goback"
    >
      返回首页
    </van-button>

    <van-popup v-model="showConfirm" position="bottom" class="confirm-popup">
      <div class="confirm-popup-content">
        <h3 class="confirm-title">确认信息</h3>
        <van-cell title="服务类型" :value="selectedService.parentText"></van-cell>
        <van-cell title="问题描述" :value="questionDescription"></van-cell>
        <van-cell title="维修工" :value="user.worker_name"></van-cell>
        <van-cell title="预计等待时间" :value="user.estimated_wait_time"></van-cell>
        <van-button color="#ec5b56" style="width: 90%; border-radius: 10px; display: block; margin: 10px auto;" @click="confirmSubmit">确认</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import UserInfoDisplay from "@/components/UserInfoDisplay.vue";
import instance from "@/axios";

export default {
  /* eslint-disable vue/multi-word-component-names */
  name: 'yuyuexiangqing',
  components: { UserInfoDisplay },
  data() {
    return {
      questionDescription: '',
      user: {
        worker_name: '',
        worker_image: '',
        process: 0,
        worker_rate: 0,
        tags: [],
        estimated_wait_time: '',
        textColor: '#ec5b56',
        statusText: ''
      },
      items: [
        {
          text: '补胎',
          id: 1,
          children: [
            {
              text: '补胎（预计完成时间：20分钟）',
              id: 11
            }
          ]
        },
        {
          text: '添加油水',
          id: 2,
          children: [
            {
              text: '给汽车加机油、冷却液、刹车油、玻璃水（预计完成时间：10分钟）',
              id: 21
            }
          ]
        },
        {
          text: '常规检查',
          id: 3,
          children: [
            {
              text: '对车辆进行常规的20项检测：7项底盘，13项油水，灯光（预计完成时间：30分钟）',
              id: 31
            }
          ]
        },
        {
          text: '机油保养',
          id: 4,
          children: [
            {
              text: '定期保养（预计完成时间：40分钟）',
              id: 41
            }
          ]
        },
           {
          text: '其他',
          id: 5,
          children: [
            {
              text: '如果您不是很清楚如何选择维修项目，请选择此选项',
              id: 51
            }
          ]
        },
          {
          text: '补气',
          id: 6,
          children: [
            {
              text: '给汽车轮胎进行充气（预计完成时间：10分钟）',
              id: 61
            }
          ]
        },
      ],
      selectedService: {},
      activeId: 0,
      activeIndex: 0,
      activeStep: 0,
      steps: [
        '等待', '预检', '检查', '维修', '取车', '评价'
      ],
      showConfirm: false
    };
  },
  methods: {
    async fetchWorkerDetail(userId) {
      try {
        const response = await instance.get(`/api/workers/${userId}`);
        const worker = response.data.data;

        // 处理 response 数据，确保所有属性类型正确
        const processedWorker = {
          worker_id: worker.worker_id,
          worker_name: worker.worker_name || '',
          worker_image: worker.worker_image || 'https://via.placeholder.com/150',
          process: worker.process || 0,
          worker_rate: parseFloat(worker.worker_rate) || 0,
          tags: worker.worker_field ? worker.worker_field.split('/') : [],
          estimated_wait_time: worker.estimated_wait_time || '',
          textColor: '#ec5b56',
          statusText: this.getStatusText(worker.status)
        };

        this.user = processedWorker;
        console.log('Worker Detail:', this.user);
      } catch (error) {
        console.error('Error fetching worker detail:', error);
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return '等待';
        case 2:
          return '预检';
        case 3:
          return '检查';
        case 4:
          return '维修';
        case 5:
          return '取车';
        case 6:
          return '评价';
        default:
          return '';
      }
    },
    onServiceSelect(item) {
      console.log(item);
      this.selectedService = {
        parentText: this.items.find(service => service.children.some(child => child.id === item.id)).text,
        text: item.text
      };
    },
    handleSubmit() {
      if (!this.selectedService.parentText || !this.questionDescription) {
        this.$toast.fail('请填写所有必填信息');
        return;
      }
      this.showConfirm = true;
    },
    goback(){
      this.$router.push('/homepage');
      console.log(localStorage.getItem('isNormal'))
    },
    async confirmSubmit() {
      try {
        const token = localStorage.getItem('token');
        console.log(token)
        const response = await instance.get('/user/info', {
          headers: { Authorization: `Bearer ${token}`}
        });
        const userInfo = response.data.data;
        console.log(this.user);
        const appointmentData = {
          customer_name: userInfo.name,
          customer_phone: userInfo.phone,
          appointment_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          service_type: this.selectedService.parentText,
          question_desc: this.questionDescription,
          status: 1,
          worker_id: this.user.worker_id,
          question_type: this.selectedService.text
        };

        await instance.post('/api/appointments', appointmentData, {
      headers: { Authorization: `Bearer ${token}` } });
        this.$toast.success('预约成功');
        this.showConfirm = false;
        this.$router.push('/yuyuechenggong');
      } catch (error) {
        this.$toast.fail('预约失败，请稍后重试');
        console.error('Error creating appointment:', error);
      }
    }
  },
  mounted() {
    const userId = this.$route.query.user_id;
    console.log('Received user_id:', userId);
    this.fetchWorkerDetail(userId);
  }
};
</script>

<style scoped>
.user-info-page {
  padding: 20px;
  background-color: #fff;
}

.van-cell {
  padding-left: 5px !important;
}

.user-info {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.user-info-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar {
  width: 100%;
  margin-top: 10px;
}

.status-text {
  font-size: 16px;
  margin-top: 10px;
  color: #ec5b56; /* 主色调红色 */
}

.user-title {
  font-size: 20px;
  color: #ec5b56;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
}

.user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-details {
  flex-grow: 1;
}

.user-id,
.user-name {
  font-size: 16px;
  color: #333;
  margin: 5px 0;
}

.change-password-button {
  background-color: #ec5b56;
  border-color: #ec5b56;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%;
}

.worker-skills {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.skills-title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.skills-list {
  list-style-type: none;
  padding: 0;
}

.skills-list li {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #666;
  font-size: 14px;
  text-align: center;
}

.user-steps {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.steps-title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Add this style to ensure multi-line text display */
.van-tree-select__content .van-tree-select__item {
  white-space: normal;
  line-height: 1.5;
}

/* 统一使用主题色 */
.van-tag--primary {
  background-color: #ec5b56 !important;
  border-color: #ec5b56 !important;
  color: #fff !important;
}

.van-progress--active .van-progress__portion {
  background-color: #ec5b56 !important;
}

.confirm-popup {
  padding: 10px;
}

.confirm-popup-content {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirm-title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.van-tree-select__item {
  color: #333!important;
}
.van-tree-select__item--active{
  color: #ec5b56 !important;
}
</style>
