<template>
  <div class="development-page">
    <div class="content">
      <i class="el-icon-s-tools icon"></i> <!-- 使用饿了么图标库的开发中图标 -->
      <h1>页面开发中</h1>
      <p>该页面正在开发中，敬请期待！</p>
      <p class="mobile-tip">您可以在移动端查看更多内容。</p>
      <el-button type="primary" @click="goBack">返回主页</el-button>
    </div>
  </div>
</template>

<script>


export default {
  methods: {
    goBack() {
      this.$router.push('/'); // 返回主页的逻辑
    },

  },
};
</script>

<style scoped>
.development-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.content {
  text-align: center;
  background-color: white;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.icon {
  font-size: 80px;
  color: #ec5b56;
  margin-bottom: 20px;
}

h1 {
  font-size: 26px;
  color: #ec5b56;
  margin-bottom: 15px;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.mobile-tip {
  font-size: 14px;
  color: #999;
  margin-bottom: 30px;
}

.el-button {
  background-color: #ec5b56;
  border-color: #ec5b56;
  color: white;
  width: 100%;
  max-width: 200px;
}
</style>

