<template>
<div>
  <van-nav-bar
  title="标题"
  left-text="返回"
  right-text="按钮"
  left-arrow
  @click-left="onClickLeft"
  @click-right="onClickRight"
/>
    <div class="container">

    <div class="ring-wrapper">

      <svg class="ring" width="100" height="100">
        <circle class="ring-bg" cx="50" cy="50" r="45" />
        <circle class="ring-progress" cx="50" cy="50" r="45" :style="progressStyle" />
      </svg>
      <div class="loading-text">检查中</div>
    </div>
    <div class="message">
      您的汽车正在检查中，可能需要30分钟，请您耐心等待。
    </div>

  </div>
  <van-tabbar active-color="#ee0a24" v-model="currentTab">
      <van-tabbar-item name="home" icon="home-o">主页</van-tabbar-item>
      <van-tabbar-item name="mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
</div>
</template>

<script>
export default {
  name: 'TiJiao',
  data() {
    return {
      progress: 0 // 初始进度为0%
    };
  },
  computed: {
    progressStyle() {
      const circumference = 2 * Math.PI * 45; // 环形的周长，半径为45px
      const offset = circumference * (1 - this.progress / 100);
      return {
        strokeDasharray: `${circumference}`,
        strokeDashoffset: `${offset}`,
      };
    }
  },
  mounted() {
    this.animateRing();
  },
  methods: {
    animateRing() {
      setInterval(() => {
        if (this.progress < 100) { // 当进度小于100%时，增加进度
          this.progress += 1;
        } else { // 当进度达到100%时，重置进度
          this.progress = 0;
        }
      }, 50); // 每50毫秒增加1%的进度
    },
    handleButtonClick() {
      this.$toast('Vant 按钮被点击!');
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
  padding: 0  20px;
  box-sizing: border-box;
}

.ring-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.ring {
  transform: rotate(-90deg);
}

.ring-bg,
.ring-progress {
  fill: none;
  stroke-width: 10;
}

.ring-bg {
  stroke: #e0e0e0;
}

.ring-progress {
  stroke: #990000;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(0.25turn);
  transform-origin: center;
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #990000;
  font-size: 14px;
  font-weight: bold;
}

.message {
  margin-top: 20px;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>