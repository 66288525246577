<template>
  <div class="register">
    <div class="content">
        <img src="https://pan.imgbed.link/file/35007" alt="Logo" class="logo" />
      <h2 class="title">注册/密码修改</h2>
      <van-field v-model="phone1" label="手机号码" placeholder="请输入手机号码" type="tel" class="input-field" />
      <van-field v-model="phone2" label="确认手机号码" placeholder="请再次输入手机号码" type="tel" class="input-field" />
      <van-button type="primary" class="full-width" round @click="submit">提交</van-button>
      <p v-if="password" class="password-display">您的随机密码是：{{ password }}</p>
      <van-button color="#ec5b56"  style="margin-top: 10px;color: #ec5b56" plain type="info" class="full-width" round @click="showPopup = true">
        修改密码
      </van-button>
       <van-button plain color="#ec5b56" style="margin-top: 10px;color: #ec5b56"  type="info" class="full-width" round @click="goback">
        返回
      </van-button>
      <div class="notice">
        <p>请及时修改默认密码，确保账户安全。</p>
      </div>
      <van-popup v-model="showPopup" position="bottom" :style="{ height: '60%' }">
        <div class="popup-content">
          <h3>修改密码</h3>
          <van-field v-model="changePasswordForm.phone" label="手机号码" placeholder="请输入手机号码" type="tel" class="input-field" />
          <van-field v-model="changePasswordForm.oldPassword" label="旧密码" placeholder="请输入旧密码" type="password" class="input-field" />
          <van-field v-model="changePasswordForm.newPassword" label="新密码" placeholder="请输入新密码" type="password" class="input-field" />
          <van-field v-model="changePasswordForm.confirmPassword" label="确认密码" placeholder="请再次输入新密码" type="password" class="input-field" />
          <van-button type="primary" class="full-width" round @click="changePassword">提交</van-button>
        </div>
      </van-popup>
      <div class="privacy-agreement">
        <p>
          注册即代表同意
          <a href="#" class="link">《奥达汽修协议》</a>
          和
          <a href="#" class="link">《隐私保护指引》</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import CryptoJS from 'crypto-js';
import instance from "@/axios";

export default {
  name: 'RegisterPage',
  data() {
    return {
      phone1: '',
      phone2: '',
      password: '',
      showPopup: false,
      changePasswordForm: {
        phone: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    };
  },
  methods: {
    validatePhone(phone) {
      const phoneRegex = /^1[3456789]\d{9}$/;
      return phoneRegex.test(phone);
    },
    submit() {
      if (this.validatePhone(this.phone1) && this.phone1 === this.phone2) {
        this.registerUser(this.phone1)
          .then(data => this.handleSuccess(data))
          .catch(error => this.handleError(error));
      } else {
        this.$toast('手机号格式不正确或两次输入不一致');
      }
    },
    registerUser(phone) {
      return instance.post('/register', { phone })
        .then(response => {
          if (response.data.status === 'fail') {
            throw new Error(response.data.message);
          }
          return response.data;
        })
        .catch(error => {
          throw new Error(error.response && error.response.data ? error.response.data.message : '注册请求失败');
        });
    },
    goback(){
      this.$router.push('/login');
    },
    handleSuccess(data) {
      this.$toast.success('注册成功');
      this.password = data.data.password;
      console.log('注册信息:', data.data);
    },
    handleError(error) {
      console.error('注册请求失败:', error);
      this.$toast.fail(error.message);
    },
    changePassword() {
      if (this.changePasswordForm.newPassword !== this.changePasswordForm.confirmPassword) {
        this.$toast('新密码和确认密码不一致');
        return;
      }
      if (this.changePasswordForm.oldPassword === this.changePasswordForm.newPassword) {
        this.$toast('新旧密码不能相同');
        return;
      }
      if (this.changePasswordForm.newPassword.length < 8 || !/\d/.test(this.changePasswordForm.newPassword) || !/[a-zA-Z]/.test(this.changePasswordForm.newPassword)) {
        this.$toast('新密码必须包含至少8个字符，包括字母和数字');
        return;
      }
      const hashedOldPassword = CryptoJS.MD5(this.changePasswordForm.oldPassword).toString();
      const hashedNewPassword = CryptoJS.MD5(this.changePasswordForm.newPassword).toString();
      this.changeUserPassword(this.changePasswordForm.phone, hashedOldPassword, hashedNewPassword)
        .then(() => {
          this.$toast.success('密码修改成功');
          this.showPopup = false;
        })
        .catch(error => this.$toast.fail(error.message));
    },
    changeUserPassword(phone, oldPassword, newPassword) {
      return instance.post('/change_password', { phone, oldPassword, newPassword })
        .then(response => {
          if (response.data.status === 'fail') {
            throw new Error(response.data.message);
          }
          return response.data;
        })
        .catch(error => {
          throw new Error(error.response && error.response.data ? error.response.data.message : '密码修改请求失败');
        });
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  }
};
</script>

<style scoped>
html, body {
  overflow: hidden; /* 禁止滚动 */
  touch-action: none; /* 禁止触摸动作 */
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff; /* 白色背景 */
  padding: 20px; /* 增加内边距，确保内容不靠边 */
}

.content {
  text-align: center;
  width: 100%;
  max-width: 400px; /* 增加最大宽度，确保在大屏设备上不太宽 */
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #ec5b56; /* 主色调 */
}

.input-field {
  margin-bottom: 20px;
}

.full-width {
  width: 100%;
  margin-bottom: 20px;
}

.password-display {
  font-size: 16px;
  color: #ec5b56; /* 主色调 */
  margin-top: 20px;
}

.notice {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.van-field__control {
  background-color: white;
}

.van-button--primary {
  background-color: #ec5b56;
  color: white;
  border: none;
}

.van-button--primary:hover,
.van-button--primary:focus {
  background-color: #d14c47;
}

.popup-content {
  padding: 20px;
}

.popup-content h3 {
  margin-bottom: 20px;
  color: #ec5b56;
}

.privacy-agreement {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  text-align: center;
}

.link {
  color: #ec5b56;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* 媒体查询确保移动端适配 */
@media (max-width: 600px) {
  .content {
    padding: 20px; /* 确保在移动设备上有足够的内边距 */
  }

  .title {
    font-size: 20px; /* 缩小标题字体大小 */
  }

  .password-display {
    font-size: 14px; /* 缩小密码显示字体大小 */
  }

  .notice {
    font-size: 12px; /* 缩小提示信息字体大小 */
  }

  .privacy-agreement {
    font-size: 12px; /* 缩小隐私协议字体大小 */
  }
}
</style>
