<template>
  <div class="container">
    <div class="login">
      <div class="content">
        <img src="https://pan.imgbed.link/file/35007" alt="Logo" class="logo" />
        <h2 class="title">管理员登录</h2>
        <van-field v-model="phone" label="手机号码" placeholder="请输入手机号码" type="tel" class="input-field" />
        <van-field v-model="password" label="密码" placeholder="请输入密码" type="password" class="input-field" />
        <van-button type="primary" class="full-width" round @click="submit">登录</van-button>
        <van-button color="#ec5b56" plain class="full-width" round @click="Gongrendenglutiaozhuan">工人登录</van-button>
        <van-button color="#ec5b56" plain class="full-width" round @click="goback">返回</van-button>
        <p class="description">
          登录即代表同意
          <a href="#" class="link">《奥达汽修协议》</a>
          和
          <a href="#" class="link">《隐私保护指引》</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'; // 导入自定义的axios实例
import CryptoJS from 'crypto-js';
import Gongrendenglu from "@/views/gongrendenglu.vue";

export default {
  name: 'WorkerLoginPage',
  computed: {
    Gongrendenglu() {
      return Gongrendenglu
    }
  },
  data() {
    return {
      phone: '',
      password: ''
    };
  },
  methods: {
    Gongrendenglutiaozhuan(){
      this.$router.push('/gongrendenglu');
    },
    validatePhone(phone) {
      const phoneRegex = /^1[3456789]\d{9}$/;
      return phoneRegex.test(phone);
    },
    submit() {
      if (this.validatePhone(this.phone) && this.password) {
        const hashedPassword = CryptoJS.MD5(this.password).toString();
        this.loginWorker(this.phone, hashedPassword)
            .then(data => this.handleSuccess(data))
            .catch(error => this.handleError(error));
      } else {
        this.$toast('请确保手机号或密码格式正确');
      }
    },
    loginWorker(phone, password) {
      return axios.post('/admin_login', {phone, password})
          .then(response => {
            console.log(response)
            if (response.data.status === 'fail') {
              throw new Error(response.data.message);

            }
            return response.data;
          })
          .catch(error => {
            throw new Error(error.response && error.response.data ? error.response.data.message : '登录请求失败');
          });
    },
    goback() {
      this.$router.go(-1);
    },
    handleSuccess(data) {
      this.$toast.success('登录成功');
      console.log('登录信息:', data.data);
      localStorage.setItem('token', data.data.token);  // 存储token
      this.$router.push('guanliyuanxiangqing');
    },
    handleError(error) {
      console.error('登录请求失败:', error);
      this.$toast.fail(error.message);
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.content {
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ec5b56;
}

.input-field {
  margin-bottom: 20px;
}

.full-width {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.van-field__control {
  background-color: white;
}

.van-button--primary {
  background-color: #ec5b56;
  color: white;
  border: none;
}

.van-button--primary:hover,
.van-button--primary:focus {
  background-color: #d14c47;
}

.description {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

.link {
  color: #ec5b56;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* 媒体查询确保移动端适配 */
@media (max-width: 600px) {
  .content {
    padding: 20px;
  }

  .title {
    font-size: 20px;
  }
}
</style>
