<template>
  <div class="container">


    <!-- 顶部总结卡片 -->
    <div class="summary-card">
      <div class="summary-item" @click="setFilter('unprocessed')">
        <div class="summary-title">未处理订单</div>
        <div class="summary-value" :class="{ active: currentFilter === 'unprocessed' }">{{ unprocessedCount }}</div>
      </div>
      <div class="summary-item" @click="setFilter('inProgress')">
        <div class="summary-title">进行中的订单</div>
        <div class="summary-value" :class="{ active: currentFilter === 'inProgress' }">{{ inProgressCount }}</div>
      </div>
      <div class="summary-item" @click="setFilter('all')">
        <div class="summary-title">全部订单</div>
        <div class="summary-value" :class="{ active: currentFilter === 'all' }">{{ totalCount }}</div>
      </div>
    </div>
 <!-- 搜索框 -->
    <div class="search-bar">
      <input v-model="searchQuery" type="text" placeholder="搜索问题描述或客户姓名" @input="filterOrders" />
    </div>
    <!-- 订单列表 -->
    <transition-group name="list" tag="div">
      <div v-for="order in paginatedOrders" :key="order.id" class="order-card" :class="statusClass(order.status)">
        <div class="order-content">
          <div class="order-header">
            <div class="customer-name">{{ order.customer_name }}</div>
            <div class="order-status">
              <span>{{ getStatusText(order.status) }}</span>
            </div>
          </div>
          <div class="order-details">
            <div class="detail-item">
              <strong style="white-space: nowrap;">服务类型:</strong> <span>{{ order.service_type }}</span>
            </div>
            <div class="detail-item">
              <strong style="white-space: nowrap;">预约日期:</strong> <span>{{ order.appointment_date }}</span>
            </div>
            <div class="detail-item">
              <strong style="white-space: nowrap;">问题描述:</strong> <span>{{ order.question_desc }}</span>
            </div>
            <div class="detail-item overdue-badge" v-if="order.status !== 3 && getElapsedTime(order.appointment_date) > 1">
              <strong style="white-space: nowrap;">已等待:</strong>
              <span>{{ getElapsedTime(order.appointment_date) }} 小时</span>
            </div>
          </div>
          <div class="action-buttons">
            <!-- 状态为 1 时显示 “预先检查” 按钮 -->
            <van-button v-if="order.status === 1" color="#ec5b56" block round @click="goToOrderDetail(order.id)">
              预先检查
            </van-button>

            <!-- 状态为 2 时显示 “开始维修” 按钮 -->
            <van-button v-if="order.status === 2" plain color="#ec5b56" block round @click="goToXiangqing(order.id)">
              开始维修
            </van-button>
          </div>
        </div>
      </div>
    </transition-group>

    <!-- 分页组件 -->
    <van-pagination
      v-model="currentPage"
      :total-items="sortedAndFilteredOrders.length"
      :items-per-page="itemsPerPage"
      @change="paginateOrders"
    />

    <!-- 新订单弹出框 -->
    <transition name="fade">
      <van-dialog v-model="showNewOrderDialog" title="新订单提示" width="80%">
        <div class="new-order-details">
          <van-cell title="客户姓名" :value="newOrder.customer_name" />
          <van-cell title="服务类型" :value="newOrder.service_type" />
          <van-cell title="问题描述" :value="newOrder.question_desc" />
          <van-cell title="预约日期" :value="newOrder.appointment_date" />
        </div>
        <template #footer>
          <van-button color="#ec5b56" @click="showNewOrderDialog = false">关闭</van-button>
        </template>
      </van-dialog>
    </transition>
  </div>
</template>

<script>
import instance from "../axios";

export default {
  data() {
    return {
      orders: [],
      sortedAndFilteredOrders: [],
      paginatedOrders: [],
      searchQuery: '',
      currentFilter: 'all',
      currentPage: 1,
      itemsPerPage: 5,
      newOrder: {}, // 用于存储新订单的信息
      showNewOrderDialog: false, // 控制新订单弹出框的显示
      previousOrders: [] // 存储上次订单列表，用于检测新订单
    };
  },
  computed: {
    unprocessedCount() {
      return this.orders.filter((order) => order.status === 1).length;
    },
    inProgressCount() {
      return this.orders.filter((order) => order.status === 2).length;
    },
    totalCount() {
      return this.orders.length;
    }
  },
  methods: {
    fetchOrders() {
      const token = localStorage.getItem('token');
      instance
        .get('/api/worker/orders', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const fetchedOrders = response.data.data;

          // 检查是否有新订单
          this.detectNewOrders(fetchedOrders);

          this.orders = fetchedOrders;
          this.filterOrders(); // 初始加载时过滤和分页
        })
        .catch((error) => {
          this.$toast.fail('获取订单失败');
          console.error(error);
        });
    },

    detectNewOrders(fetchedOrders) {
      if (this.previousOrders.length === 0) {
        this.previousOrders = fetchedOrders;
        return;
      }

      const latestPreviousOrder = this.previousOrders.reduce((latest, order) => {
        return new Date(order.appointment_date) > new Date(latest.appointment_date)
          ? order
          : latest;
      }, this.previousOrders[0]);

      const newOrder = fetchedOrders.find(
        (order) =>
          new Date(order.appointment_date) > new Date(latestPreviousOrder.appointment_date)
      );

      if (newOrder) {
        this.newOrder = newOrder;
        this.showNewOrderDialog = true; // 显示新订单弹出框
      }

      this.previousOrders = fetchedOrders;
    },

    getElapsedTime(appointmentDate) {
      const currentTime = new Date();
      const appointmentTime = new Date(appointmentDate);
      const hoursElapsed = Math.floor((currentTime - appointmentTime) / (1000 * 60 * 60));
      return hoursElapsed;
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return '待处理';
        case 2:
          return '处理中';
        case 3:
          return '已完成';
        default:
          return '未知状态';
      }
    },
    statusClass(status) {
      if (status === 3) return 'completed';
      if (status === 2) return 'in-progress';
      return 'pending';
    },
    goToOrderDetail(orderId) {
      const token = localStorage.getItem('token');
      instance
        .post(
          `/api/orders/${orderId}/update-status`,
          { status: 2 },  // 更新状态为处理中
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          this.$toast.success('订单状态已更新为处理中');
          this.fetchOrders();
        })
        .catch((error) => {
          this.$toast.fail('更新订单状态失败');
          console.error(error);
        });
    },
    goToXiangqing(orderId) {
      this.$router.push(`/weixiuxiangqing/${orderId}`);
    },
    setFilter(filter) {
      this.currentFilter = filter;
      this.currentPage = 1; // 重置为第一页
      this.filterOrders();  // 更新筛选条件后，重新过滤和分页
    },
    filterOrders() {
      const searchLower = this.searchQuery.trim();
      this.sortedAndFilteredOrders = this.orders.filter((order) => {
        const customerName = order.customer_name || '';
        const questionDesc = order.question_desc || '';
        const serviceType = order.service_type || '';

        return (
          (this.currentFilter === 'all' ||
            (this.currentFilter === 'unprocessed' && order.status === 1) ||
            (this.currentFilter === 'inProgress' && order.status === 2)) &&
          (
            customerName.includes(searchLower) ||
            questionDesc.includes(searchLower) ||
            serviceType.includes(searchLower)
          )
        );
      }).sort((a, b) => new Date(b.appointment_date) - new Date(a.appointment_date));

      this.paginateOrders();  // 处理分页
    },
    paginateOrders() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedOrders = this.sortedAndFilteredOrders.slice(start, end);
    }
  },
  mounted() {
    this.fetchOrders();
    setInterval(this.fetchOrders, 60000); // 每60秒刷新一次订单列表
  },
  watch: {
    currentPage() {
      this.paginateOrders();  // 当前页改变时更新分页内容
    }
  }
};
</script>

<style scoped>

/* 通用布局和颜色 */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h3, h4 {
  margin: 0;
}

strong {
  color: #333;
}

/* 搜索框 */
.search-bar {
  width: 95%;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

/* 顶部总结卡片 */
.summary-card {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px;
}

.summary-item {
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.summary-item:hover {
  background-color: #f9f9f9;
}

.summary-title {
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
}

.summary-value {
  font-size: 24px;
  color: #333;
}

.summary-value.active {
  color: #ec5b56;
}
::v-deep .van-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

::v-deep .van-pagination__item {
  color: #ec5b56;
  border-radius: 10px; /* 设置圆角 */
  padding: 8px 16px; /* 增加内边距使按钮看起来更大 */
  transition: all 0.3s ease;
}

::v-deep .van-pagination__item:hover {
  background-color: #ec5b5610; /* 主题色的浅色背景 */
}

::v-deep .van-pagination__item--active {
  background-color: #ec5b56; /* 使用主题色作为背景 */
  color: white; /* 文字颜色为白色 */
}

::v-deep .van-pagination__prev,
::v-deep .van-pagination__next {
  color: #ec5b56;
}

::v-deep .van-pagination__prev:hover,
::v-deep .van-pagination__next:hover {
  color: #d94b4b; /* 主题色的深色 */
}
/* 订单卡片 */
.order-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.order-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.order-card.completed {
  background-color: #f4f4f4;
  color: #a0a0a0;
}

.order-card.in-progress {
  border-left: 4px solid #ec5b56;
}

.order-card.pending {
  border-left: 4px solid #ec5b56;
  opacity: 0.9;
}

/* 等待超过1小时的订单增加红色圆角和背景 */
.overdue-badge {
  padding: 5px;
  background-color: #ffefef;
  color: #e74c3c;
  border-radius: 8px;
}

/* 订单内容布局 */
.order-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.order-status {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  background-color: #ec5b56;
  color: white;
  border-radius: 12px;
}

.order-details {
  font-size: 15px;
  line-height: 1.6;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-item {
  display: flex;
  text-align: right;
  justify-content: space-between;
}

.detail-item strong {
  font-weight: 500;
}

.detail-item span {
  color: #666;
}

/* 动作按钮 */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.van-button {
  width: 100%;
}

.van-button.primary {
  background-color: #ec5b56;
  color: white;
}

.van-button.primary:hover {
  background-color: #d94b4b;
}

.van-button.plain {
  color: #ec5b56;
  border: 1px solid #ec5b56;
}

.van-button.plain:hover {
  background-color: rgba(236, 91, 86, 0.1);
}

.summary-item:hover {
  border-radius: 10px;
  background-color: #ec5b5610;
}

/* 动画效果 */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter {
  transform: translateY(10px);
  opacity: 0;
}

.list-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

/* 新订单弹出框 */
.new-order-details {
  text-align: left;
  padding: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
