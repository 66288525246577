import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import RegisterPage from '@/views/RegisterPage.vue';
import ComplexPage from "@/views/ComplexPage.vue";
import yuyuexiangqing from "@/views/yuyuexiangqing.vue";
import tiJiao from "@/views/tiJiao.vue";
import pingJia from "@/views/pingJia.vue";
import xiuGaimima from "@/views/xiuGaimima.vue";
import login from "@/views/login.vue";
import yuyuechenggong from "@/views/yuyuechenggong.vue";
import quanbudingdan from "@/views/quanbudingdan.vue";
import dingdanxiangqing from "@/views/dingdanxiangqing.vue";
import worker_selection from "@/views/worker_selection.vue";
import gongrendenglu from "@/views/gongrendenglu.vue";
import gongrendingdan from "@/views/gongrendingdan.vue";
import weixiuxiangqing from "@/views/weixiuxiangqing.vue";
import guanliyuandenglu from "@/views/guanliyuandenglu.vue";
import guanliyuanxiangqing from "@/views/guanliyuanxiangqing.vue";
import gongrenzhuce from "@/views/gongrenzhuce.vue";
import guanliyuanxiangqingpc from "@/views/guanliyuanxiangqingpc.vue";
import wechatprompt from "@/views/wechatprompt.vue";

Vue.use(Router);

// 创建路由实例
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
       {
      path: '/wechatprompt',
      name: 'wechatprompt',
      component: wechatprompt
    },
    {
      path: '/quanbudingdan',
      name: 'quanbudingdan',
      component: quanbudingdan,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/gongrenzhuce',
      name: 'gongrenzhuce',
      component: gongrenzhuce
    },
      {
      path: '/guanliyuanxiangqingpc',
      name: 'guanliyuanxiangqingpc',
      component: guanliyuanxiangqingpc,
    },
    {
      path: '/guanliyuanxiangqing',
      name: 'guanliyuanxiangqing',
      component: guanliyuanxiangqing,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/guanliyuandenglu',
      name: 'guanliyuandenglu',
      component: guanliyuandenglu
    },
    {
      path: '/gongrendingdan',
      name: 'gongrendingdan',
      component: gongrendingdan,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/gongrendenglu',
      name: 'gongrendenglu',
      component: gongrendenglu
    },
    {
      path: '/dingdanxiangqing/:orderId',
      name: 'dingdanXiangqing',
      component: dingdanxiangqing,
      props: true,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/weixiuxiangqing/:orderId',
      name: 'weixiuxiangqing',
      component: weixiuxiangqing,
      props: true,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/worker-selection/:shopId',
      name: 'worker_selection',
      component: worker_selection,
      props: true,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/yuyuechenggong',
      name: 'yuyuechenggong',
      component: yuyuechenggong,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/xiuGaimima',
      name: 'xiuGaimima',
      component: xiuGaimima
    },
    {
      path: '/pingJia/:orderId',
      name: 'pingJia',
      component: pingJia,
      props: true,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/tiJiao',
      name: 'tiJiao',
      component: tiJiao,
      meta: { requiresAuth: true } // 需要登录访问
    },
    {
      path: '/register',
      name: 'RegisterPage',
      component: RegisterPage
    },
    {
      path: '/homepage',
      name: 'homepage',
      component: ComplexPage
    },
    {
      path: '/yuyuexiangqing',
      name: 'yuyuexiangqing',
      component: yuyuexiangqing,
      meta: { requiresAuth: true } // 需要登录访问
    }
  ]
});

// 路由守卫，检查每个路由是否需要登录
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('token'); // 检查 localStorage 中是否有 token
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('/login'); // 如果没有登录，重定向到登录页面
  } else {
    next(); // 否则，继续导航
  }
});

export default router;
