<template>
  <div class="success-page">
    <div class="content">
      <van-icon name="success" size="100px" color="#ec5b56" />
      <h2 class="title">预约成功</h2>
      <p class="description">
        请您耐心等待，在检查完成后，师傅会将详细的维修单反馈给您。
      </p>
    </div>

    <!-- 动态生成的二维码区域 -->
    <div class="qr-section">
      <canvas ref="qrCodeCanvas" class="qr-code"></canvas> <!-- 使用 Canvas 生成二维码 -->
      <p class="qr-text">扫一扫提前得知维修进度</p>
    </div>

    <van-button round block type="primary" color="#ec5b56" class="center-button" @click="goBack">
      返回首页
    </van-button>
  </div>
</template>

<script>
import QRCode from 'qrcode'; // 导入 qrcode 库

// eslint-disable vue/multi-word-component-names
export default {
  name: 'yuyuechenggong',
  data() {
    return {
      qrContent: 'http://aodaautorepair.store/#/quanbudingdan', // 可配置的二维码内容
    };
  },
  mounted() {
    // 禁止页面缩放和滚动
    document.addEventListener('touchmove', this.preventScroll, { passive: false });

    // 动态生成二维码
    this.generateQRCode();
  },
  beforeDestroy() {
    // 在页面销毁之前移除事件监听
    document.removeEventListener('touchmove', this.preventScroll);
  },
  methods: {
    goBack() {
      // 模拟返回首页的功能
      this.$router.push('/homepage');
    },
    preventScroll(event) {
      event.preventDefault(); // 禁止滚动
    },
    generateQRCode() {
      const canvas = this.$refs.qrCodeCanvas; // 获取 canvas 元素
      QRCode.toCanvas(canvas, this.qrContent, {
        width: 150, // 设置二维码的大小
        color: {
          dark: '#ec5b56', // 生成二维码的颜色
          light: '#ffffff', // 背景颜色
        },
      }, (error) => {
        if (error) {
          console.error('二维码生成失败:', error);
        } else {
          console.log('二维码生成成功');
        }
      });
    },
  },
};
</script>

<style scoped>
.success-page {
  padding-top: 60px;
  text-align: center;
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden; /* 禁止页面滚动 */
}

.content {
  margin: 20px;
}

.title {
  font-size: 22px;
  color: #333;
  margin-top: 20px;
}

.description {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.qr-section {
  margin: 20px 0;
  text-align: center;
}

.qr-code {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.qr-text {
  font-size: 14px;
  color: #666;
}

.center-button {
  width: 85%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>
