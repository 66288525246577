<template>
  <div class="wechat-prompt-page">
    <div class="content">
      <i class="el-icon-warning-outline icon"></i> <!-- 警告图标 -->
      <p>由于微信限制，您无法直接打开导航链接</p>
      <h1>请点击右上角，选择“在浏览器打开”</h1>
      <p>您可以选择直接打开链接</p>

      <!-- 直接打开链接按钮 -->
      <div class="link-container">
        <el-input v-model="deviceUrl" readonly></el-input>
        <el-button :disabled="isdisabled" style="margin-top: 10px;border:0px solid !important;" type="primary" @click="openLink">直接打开链接</el-button>
      </div>

      <!-- 分割线 -->
      <van-divider>或者，复制后手动粘贴到浏览器中打开</van-divider>

      <!-- 复制链接区域 -->
<!--      <p class="note">。</p>-->
      <div class="copy-container">
        <el-button type="default" @click="copyToClipboard">复制链接</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {

    return {
      isdisabled:false,
      deviceUrl: '' // 将导航链接存储在此
    };
  },
  mounted() {
    // 根据设备类型设置 URL
    if (this.isIOS()) {
      this.deviceUrl = this.$route.query.iosUrl;
    } else {
      this.deviceUrl = this.$route.query.androidUrl;
    }

    // 判断是否为微信浏览器
    if (!this.isWeChatBrowser()) {
      this.openLink(); // 非微信浏览器直接打开链接
      this.isdisabled = false;
    }
  },
  methods: {
    // 判断是否是 iOS 系统
    isIOS() {
      const ua = navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(ua);
    },

    // 判断是否是微信浏览器
    isWeChatBrowser() {
      const ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua);
    },

    // 直接打开链接
    openLink() {
      if(!this.isWeChatBrowser()){
        window.location.href = this.deviceUrl;
      }else {
        this.$toast.fail('请在系统浏览器打开')
      }

    },

    // 复制链接到剪贴板
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.deviceUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$toast.success('链接已复制，粘贴到系统浏览器打开');
    }
  }
};
</script>

<style scoped>
.wechat-prompt-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.content {
  text-align: center;
  background-color: white;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.icon {
  font-size: 80px;
  color: #ec5b56;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
  color: #ec5b56;
  margin-bottom: 15px;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-input {
  width: 100%;
  margin-bottom: 10px;
}

.el-button {
  background-color: #ec5b56;
  border-color: #ec5b56;
  color: white;
}

.el-button:hover {
  background-color: #d94c48;
}

.van-divider {
  margin: 20px 0;
  color: #999;
}

/* 复制链接区域样式 */
.copy-container {
  display: flex;
  justify-content: center;
}

.note {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}

/* 复制链接按钮样式 */
.copy-container .el-button {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #666;
}

.copy-container .el-button:hover {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #666;
}
</style>
