<template>
  <div >
    <!-- 滑动完成功能 -->
    <div ref="sliderWrapper" class="slider-wrapper">
      <div class="slider-background" :style="{ width: isComplete ? '100%' : `${sliderBgWidth}px` }">
        <div
          class="slider-text"
          :style="{ color: `rgba(255, 255, 255, ${sliderBgWidth / maxOffset})` }"
        >
          {{ isComplete ? '操作已完成' : '滑动以结束订单' }}
        </div>
      </div>
      <div
        ref="sliderButton"
        class="slider-button"
        v-if="!isComplete"
        :style="{ transform: `translateX(${offsetX}px)` }"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <img src="https://img.icons8.com/ios-filled/50/ffffff/checkmark.png" alt="icon" />
      </div>
    </div>
  </div>
</template>

<script>

import instance from "@/axios";

export default {
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      startX: 0,  // 记录触摸起点
      offsetX: 0,  // 滑动的位移
      maxOffset: 0,  // 最大滑动距离
      sliderBgWidth: 0,  // 滑动背景的宽度
      isComplete: false,  // 是否完成滑动
    };
  },
  mounted() {
    // 计算最大可滑动距离
    this.maxOffset = this.$refs.sliderWrapper.offsetWidth - this.$refs.sliderButton.offsetWidth;
  },
  methods: {
    onTouchStart(event) {
      // 记录初始触摸位置
      this.startX = event.touches[0].clientX;
    },
    onTouchMove(event) {
      // 计算滑动距离
      const moveX = event.touches[0].clientX - this.startX;
      if (moveX > 0 && moveX <= this.maxOffset) {
        this.offsetX = moveX;
        this.sliderBgWidth = moveX;
      }
    },
    onTouchEnd() {
      // 滑动距离足够时，标记完成
      if (this.offsetX >= this.maxOffset * 0.85) {
        this.offsetX = this.maxOffset;
        this.sliderBgWidth = this.maxOffset;
        this.isComplete = true; // 标记为已完成，背景填满
        this.handleComplete();
      } else {
        // 滑动不够远则复位
        this.offsetX = 0;
        this.sliderBgWidth = 0;
      }
    },
    handleComplete() {
      // 滑动完成后的操作
      this.$toast.success('订单已完成！');
      instance.post(`/api/orders/${this.orderId}/complete`)
    .then(response => {
        console.log(response.data.message);
        this.$router.push({ path: `/gongrendingdan` });
    })
    .catch(error => {
        console.error(error.response.data.error);
    });

    }
  }
};
</script>

<style scoped>
.container {

}

.order-card {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 95%;
  max-width: 500px;
  margin-bottom: 20px;
}

.order-title {
  font-size: 22px;
  color: #ec5b56;
  font-weight: bold;
  margin-bottom: 15px;
}

.order-details {
  margin-bottom: 20px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  color: #666;
}

.value {
  color: #333;
  text-align: right;
}

.slider-wrapper {
  width: 100%;
  max-width: 500px;
  height: 60px;
  background-color: #dcdcdc;
  border-radius: 30px;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
}

.slider-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ec5b56;
  border-radius: 30px;
  z-index: 1;
  transition: width 0.3s ease;
}

.slider-text {
  font-size: 18px;
  color: rgba(255, 255, 255, 0);
  transition: color 0.3s ease;
}

.slider-button {
  width: 60px;
  height: 60px;
  background-color: #ec5b56;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  transition: transform 0.3s;
}

.slider-button img {
  width: 30px;
  height: 30px;
}
</style>
