<template>
  <div class="container">
    <div class="login">
      <div class="content">
        <img src="https://pan.imgbed.link/file/35007" alt="Logo" class="logo" />
        <h2 class="title">客户登录</h2>
        <van-field v-model="phone" label="手机号码" placeholder="请输入手机号码" type="tel" class="input-field" />
        <van-field v-model="password" label="密码" placeholder="请输入密码" type="password" class="input-field" />
        <van-button type="primary" class="full-width" round @click="submit">客户登录</van-button>
          <van-button plain class="full-width" round @click="gongrendenglu" color="#ec5b56">员工登录</van-button>
         <van-button type="info" color="#ec5b56" plain class="full-width" round @click="goToRegister">注册</van-button>

        <p class="description">
          登录即代表同意
          <a href="#" class="link">《奥达汽修协议》</a>
          和
          <a href="#" class="link">《隐私保护指引》</a>
        </p>
       </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'; // 导入自定义的axios实例
import CryptoJS from 'crypto-js';

export default {
  name: 'LoginPage',
  data() {
    return {
      phone: '',
      password: ''
    };
  },
  methods: {
    addMetaViewport() {
    let meta = document.querySelector('meta[name="viewport"]');
    if (!meta) {
      meta = document.createElement('meta');
      meta.name = 'viewport';
      document.head.appendChild(meta);
    }
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
  },
  // 禁用双击缩放
  preventZoom(e) {
    e.preventDefault();
  },
  // 禁用双指缩放
  preventPinchZoom(e) {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  },
  // 禁用 iOS 键盘输入放大行为
  preventKeyboardZoom(e) {
    const target = e.target;
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
      target.style.fontSize = '16px'; // 适当调整输入框字体大小，避免缩放
    }
  },
    validatePhone(phone) {
      const phoneRegex = /^1[3456789]\d{9}$/;
      return phoneRegex.test(phone);
    },
    submit() {
      if (this.validatePhone(this.phone) && this.password) {
        const hashedPassword = CryptoJS.MD5(this.password).toString();
        this.loginUser(this.phone, hashedPassword)
            .then(data => this.handleSuccess(data))
            .catch(error => this.handleError(error));
      } else {
        this.$toast('请确保手机号或密码格式正确');
      }
    },
    loginUser(phone, password) {
      return axios.post('/customer_login', {phone, password})
          .then(response => {
            if (response.data.status === 'fail') {
              throw new Error(response.data.message);
            }
            return response.data;
          })
          .catch(error => {
            throw new Error(error.response && error.response.data ? error.response.data.message : '登录请求失败');
          });
    },
    gongrendenglu(){
      this.$router.push('/gongrendenglu');
    },
    handleSuccess(data) {
      this.$toast.success('登录成功');
      console.log('登录信息:', data.data);
      localStorage.setItem('token', data.data.token);  // 存储token
      this.$router.push('/homepage');  // 假设有一个主页路由
    },
    handleError(error) {
      console.error('登录请求失败:', error);
      this.$toast.fail(error.message);
    },
    goToRegister() {
      this.$router.push('/register');  // 假设你的注册页面路由是 /register
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden';
    this.addMetaViewport();

  // 禁用双击缩放
  document.addEventListener('dblclick', this.preventZoom);

  // 禁用双指缩放
  document.addEventListener('touchmove', this.preventPinchZoom, { passive: false });

  // 禁用键盘输入放大
  document.addEventListener('touchstart', this.preventKeyboardZoom, { passive: false });

  },
  beforeDestroy() {
    document.body.style.overflow = '';
    document.removeEventListener('dblclick', this.preventZoom);
  document.removeEventListener('touchmove', this.preventPinchZoom);
  document.removeEventListener('touchstart', this.preventKeyboardZoom);
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.content {
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ec5b56;
}

.input-field {
  margin-bottom: 20px;
}

.full-width {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.van-field__control {
  background-color: white;
}

.van-button--primary {
  background-color: #ec5b56;
  color: white;
  border: none;
}

.van-button--primary:hover,
.van-button--primary:focus {
  background-color: #d14c47;
}

.description {
  font-size: 14px;
  color: #666;
  margin-top: 20px; /* 放置在注册按钮下面20px的距离 */
}

.link {
  color: #ec5b56;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* 媒体查询确保移动端适配 */
@media (max-width: 600px) {
  .content {
    padding: 20px;
  }

  .title {
    font-size: 20px;
  }
}
</style>
