<template>
  <div>
    <div class="container">
      <img src="https://pan.imgbed.link/file/35007" class="logo" />
      <van-divider content-position="center" custom-style="color: #ec5b5690; border-color: #ec5b5660; font-size: 14px;">
        轻点进行评分
      </van-divider>

      <div class="rating-container" v-if="!isRated">
        <van-rate
          v-model="value"
          allow-half
          size="30"
          color="#ec5b56"
          void-icon="star"
          void-color="#eee"
          @change="onChange"
        />
      </div>

      <div class="feedback-container" v-if="!isRated">
        <van-toast id="custom-selector" />
        <van-cell-group>
          <van-field
            center
            v-model="message"
            label="服务评价"
            size="large"
            type="textarea"
            placeholder="请输入您对于我们本次的服务评价"
            autosize
            :border="false"
          />
        </van-cell-group>

        <van-toast id="custom-selector" />

        <!-- 提交按钮，未获取到workerId时禁用 -->
        <van-button
          style="border-radius: 15px; margin-top: 100px"
          color="#ec5b56"
          @click="submit"
          type="primary"
          block
          :disabled="!workerId"
        >
          提交评价
        </van-button>

        <van-button
          plain
          style="margin-top: 10px; border-radius: 15px"
          @click="fanhui"
          color="#ec5b56"
          block
        >
          返回
        </van-button>
      </div>

      <div v-else class="rated-message">
        <p class="rated-text">该订单已完成评价，不可再次修改。</p>
      </div>

      <div class="footer">
        <div>感谢您选择奥达汽修</div>
        <div v-if="!isRated">请您对我们的服务进行评价</div>
        <div v-else>您的评价已提交，感谢您的支持！</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Rate, Field, CellGroup, Button, Divider } from 'vant';
import 'vant/lib/index.css';
import instance from "@/axios"; // 导入 axios

export default {
  data() {
    return {
      value: 0, // 评分值
      message: '', // 用户的评价内容
      workerId: null, // 工人ID，初始为空
      orderId: null, // 订单ID
      isRated: false, // 订单是否已经完成评价
    };
  },
  mounted() {
    this.orderId = this.$route.params.orderId;  // 获取路由参数中的订单ID
    this.getOrderStatus(this.orderId);  // 检查订单状态
  },
  methods: {
    // 监听评分变化
    onChange(value) {
      this.value = value;
    },
    // 获取订单状态，判断是否已评价
    getOrderStatus(orderId) {
      instance
        .get(`/api/orders/${orderId}`)
        .then((response) => {
          const order = response.data;
          if (order.status === 4) {
            this.isRated = true; // 如果订单已评价，设置 isRated 为 true
          } else {
            this.getWorkerByOrderId(orderId); // 如果未评价，获取工人信息
          }
        })
        .catch((error) => {
          Toast.fail('获取订单状态失败');
          console.error(error);
        });
    },
    // 根据订单ID获取工人信息
    getWorkerByOrderId(orderId) {
      instance
        .get(`/api/order/${orderId}/worker`)
        .then((response) => {
          const worker = response.data;
          this.workerId = worker.worker_id; // 获取到workerId后启用提交按钮
        })
        .catch((error) => {
          Toast.fail('获取工人信息失败');
          console.error(error);
        });
    },
    // 提交评价
    submit() {
      if (this.value === 0 || this.message.trim() === '') {
        Toast.fail('请完成评分并填写评价内容');
        return;
      }

      const ratingData = {
        rate: this.value, // 评分
        message: this.message, // 评价内容
        order_id: this.orderId,
      };

      // 提交评分到后端
      instance
        .post(`/api/worker/${this.workerId}/rate`, ratingData)
        .then((response) => {
          console.log(response);
          Toast.success('评价提交成功');
          setTimeout(() => {
            this.$router.push('/quanbudingdan'); // 跳转到首页
          }, 1500);
        })
        .catch((error) => {
          Toast.fail('提交失败，请稍后重试');
          console.error(error);
        });
    },
    // 返回订单页面
    fanhui() {
      this.$router.push('/quanbudingdan'); // 跳转到订单页面
    },
  },
  components: {
    [Toast.name]: Toast,
    [Rate.name]: Rate,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
  },
};
</script>

<style scoped>
.container {
  background-color: white !important;
  padding: 10px;
  text-align: center;
  margin-top: 5vh;
}

.logo {
  width: 100px;
  border-radius: 10px;
}

.rating-container {
  margin-top: 1vh;
}

.feedback-container {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
}

.rated-message {
  margin-top: 50px;
  font-size: 18px;
  color: #ec5b56;
  text-align: center;
}

.footer {
  margin-top: 2vh;
  font-size: 12px;
  text-align: center;
  color: slategray;
}

.footer div {
  margin-top: 0.5vh;
}
</style>
