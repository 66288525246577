<template>
  <div class="order-details-page">
    <transition name="fade-in">
      <h1 class="title">订单详情</h1>
    </transition>

    <!-- 二维码 -->
    <transition name="fade-in">
      <div class="qr-code-container">
        <img :src="qrCodeUrl" alt="订单二维码" class="qr-code" />
        <p class="qr-code-text">扫码查看PDF维修单</p> <!-- 提示扫码查看维修单 -->
      </div>
    </transition>

    <!-- 进度条，根据订单状态显示 -->
    <transition name="slide-in">
      <van-steps :active="activeStep" active-color="#ec5b56">
        <van-step>等待</van-step>
        <van-step>预检</van-step>
        <van-step>检查</van-step>
        <van-step>维修</van-step>
        <van-step>取车</van-step>
        <van-step>评价</van-step>
      </van-steps>
    </transition>

    <!-- 订单信息块 -->
    <transition name="fade-in">
      <div class="info-block" v-if="order">
        <h2 class="block-title">订单信息</h2>
        <div class="info-row highlighted">
          <span class="info-title">订单编号:</span>
          <span class="info-content">{{ order.id ?? '暂无编号' }}</span>
        </div>
        <div class="info-row">
          <span class="info-title">服务类型:</span>
          <span class="info-content">{{ order.service_type ?? '暂无服务类型' }}</span>
        </div>
        <div class="info-row">
          <span class="info-title">问题描述:</span>
          <span class="info-content">{{ order.question_desc ?? '暂无问题描述' }}</span>
        </div>
        <div class="info-row">
          <span class="info-title">问题类型:</span>
          <span class="info-content">{{ order.question_type ?? '暂无问题类型' }}</span>
        </div>
        <div class="info-row">
          <span class="info-title">预约时间:</span>
          <span class="info-content">{{ formatDate(order.appointment_date) ?? '暂无预约时间' }}</span>
        </div>
        <div class="info-row">
          <span class="info-title">订单状态:</span>
          <span class="info-content-status">{{ formatStatus(order.status) ?? '暂无状态' }}</span>
        </div>
        <div class="info-row">
          <span class="info-title">预检问题类型:</span>
          <span class="info-content-status">{{ order.checeked_type ?? '暂无结果或尚未开始' }}</span>
        </div>
         <div class="info-row">
          <span class="info-title">检查结果:</span>
          <span class="info-content-status">{{ order.jianchajieguo ?? '暂无结果或尚未开始' }}</span>
        </div>
         <div class="info-row">
          <span class="info-title">维修方案:</span>
          <span class="info-content-status">{{ order.weixiufangan ?? '暂无方案或尚未开始' }}</span>
        </div>
         <div class="info-row">
          <span class="info-title">预计完成时间:</span>
          <span class="info-content-status">{{ order.yujiwanchengshijian ?? '暂无' }}</span>
        </div>

        <!-- 维修进度条 -->
        <transition name="slide-in">
          <div class="progress-section">
            <label class="info-title">维修进度: {{ progressValue ? progressValue + '%' : '暂无进度' }}</label>
            <progress style="margin-top: 10px" class="progress-bar" :value="progressValue" max="100"></progress>
          </div>
        </transition>

        <!-- 如果订单超时，且状态不是已完成或评价，则显示提示 -->
        <transition name="fade-in">
          <div v-if="isOverdue && order.status !== 3 && order.status !== 4" class="overdue-alert">
            <p>订单状态异常，请联系工作人员！</p>
          </div>
        </transition>
      </div>
    </transition>

    <!-- 维修工人信息块 (名片样式) -->
    <transition name="slide-up">
      <div class="info-block worker-card" v-if="worker">
        <div class="worker-card-content">
          <img :src="worker.worker_image" alt="工人图片" class="worker-image" />
          <div class="worker-details">
            <h2 class="worker-name">{{ worker.worker_name ?? '暂无工人姓名' }}</h2>
            <van-rate v-model="worker.worker_rate" color="#ec5b56" size="20" readonly />
            <p class="worker-field">擅长领域: {{ worker.worker_field ?? '暂无工人领域' }}</p>
            <p class="worker-wait-time">预计等待时间: {{ worker.estimated_wait_time ?? '暂无预计等待时间' }}</p>
          </div>
        </div>
      </div>
    </transition>

    <!-- 返回按钮 -->
    <transition name="slide-in">
      <van-button type="primary" block @click="goBack" color="#ec5b56" style="margin-bottom: 15px; border-radius: 15px">
        返回
      </van-button>
    </transition>

    <!-- 显示评价按钮，当订单状态为已完成时 -->
    <transition name="slide-up">
      <van-button v-if="order && order.status === 3" plain block @click="goToReview" color="#ec5b56" style="border-radius: 15px">
        评价
      </van-button>
    </transition>
    <transition name="slide-up">
      <van-button v-if="order && order.status === 4" plain block disabled color="#ec5b56" style="border-radius: 15px">
        已完成评价，不可多次评价
      </van-button>
    </transition>
  </div>
</template>

<script>
import instance from '@/axios'; // 引入配置好的 Axios 实例
import QRCode from 'qrcode'; // 引入 QRCode 生成库

export default {
  data() {
    return {
      orderId: '',
      order: null,
      worker: null, // 维修工人信息
      themeColor: '#ec5b56', // 主题色
      qrCodeUrl: '', // 用于存储生成的二维码 URL
      activeStep: 0, // 当前进度条的步数
      progressValue: 0, // 进度条的数值
      isOverdue: false, // 用于判断订单是否超时
    };
  },
  created() {
    this.orderId = this.$route.params.orderId;
    this.fetchOrderDetails();
    this.generateQRCode(); // 生成二维码
  },
  methods: {
    // 生成二维码，并应用主题色
    async generateQRCode() {
      const url = `http://8.130.116.200:8081/generate_pdf?order_id=${this.orderId}`;
      try {
        this.qrCodeUrl = await QRCode.toDataURL(url, { color: { dark: '#ec5b56', light: '#ffffff' } }); // 应用主题色
      } catch (error) {
        console.error('二维码生成失败', error);
      }
    },
    // 获取订单详情和维修工人信息
    async fetchOrderDetails() {
      try {
        const response = await instance.get(`/api/orders/${this.orderId}`);
        if (response.data.status === 'success') {
          this.order = response.data.data;
          this.updateProgress(this.order); // 根据订单状态更新进度条
          this.updateStepProgress(this.order.status);
          this.fetchWorkerDetails(this.order.worker_id); // 级联查询工人信息
        } else {
          this.$toast.fail(response.data.message);
        }
      } catch (error) {
        this.$toast.fail('获取订单详情失败');
      }
    },
    // 根据工人ID获取维修工人信息
    async fetchWorkerDetails(workerId) {
      try {
        const response = await instance.get(`/api/workers/${workerId}`);
        if (response.data.status === 'success') {
          this.worker = response.data.data;
        } else {
          this.$toast.fail(response.data.message);
        }
      } catch (error) {
        this.$toast.fail('获取工人信息失败');
      }
    },
    // 更新进度条，基于订单时间和预计完成时间
    updateProgress(order) {
      if (!order.yujiwanchengshijian) {
        // 如果预计完成时间为 null，显示 "等待工人填写"
        this.progressValue = 0;
        this.isOverdue = false;
      } else {
        const appointmentDate = new Date(order.appointment_date).getTime();
        const completionDate = new Date(order.yujiwanchengshijian).getTime();
        const currentDate = Date.now();

        // 检查是否预约时间大于预计完成时间
        if (appointmentDate > completionDate) {
          this.progressValue = 100; // 超时或已经完成
        } else {
          // 计算进度百分比 (当前时间 - 预约时间) / (预计完成时间 - 预约时间)
          const progress = ((currentDate - appointmentDate) / (completionDate - appointmentDate)) * 100;
          this.progressValue = Math.min(Math.max(progress, 0), 100); // 保持进度在 0% - 100% 之间
        }

        // 如果当前时间超过预计完成时间且状态不是 3 或 4，显示提示
        this.isOverdue = currentDate > completionDate && order.status !== 3 && order.status !== 4;
      }
    },
    updateStepProgress(status) {
      const statusMap = {
        1: 0, // 等待
        2: 2, // 检查
        3: 4, // 取车
        4: 5, // 评价
      };
      this.activeStep = statusMap[status] || 0; // 设置进度
    },
    goBack() {
      this.$router.push('/quanbudingdan'); // 返回订单列表页面
    },
    goToReview() {
      this.$router.push(`/pingjia/${this.order.id}`); // 通过动态参数传递 orderId
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString();
    },
    formatStatus(status) {
      const statusMap = {
        1: '待处理',
        2: '处理中',
        3: '已完成',
        4: '已完成评价'
      };
      return statusMap[status] || '未知状态';
    },
  }
};
</script>

<style scoped>
/* 页面背景设置为白色 */
.order-details-page {
  min-height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

.title {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
}

/* 二维码样式 */
.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.qr-code {
  width: 120px;
  height: 120px;
  border-radius: 8px;
}

.qr-code-text {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}

/* 订单信息块 */
.info-block {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.block-title {
  font-size: 18px;
  font-weight: bold;
  color: #ec5b56;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.highlighted {
  font-size: 16px;
  font-weight: bold;
}

.info-title {
  font-weight: bold;
  color: #333;
}

.info-content {
  text-align: right;
  color: #666;
  word-break: break-word;
  max-width: 65%;
}

.info-content-status {
  text-align: right;
  color: #ec5b56;
  font-weight: bold;
  word-break: break-word;
  max-width: 65%;
}

/* 进度条样式 */
.progress-section {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.progress-bar {
  width: 100%;
  height: 16px; /* 提高进度条高度 */
  border-radius: 10px; /* 增加圆角 */
  background-color: #f0f0f0;
  overflow: hidden; /* 保持圆角边框效果 */
}

.progress-bar::-webkit-progress-bar {
  border-radius: 10px; /* 圆角 */
  background-color: #f0f0f0; /* 背景色 */
}

.progress-bar::-webkit-progress-value {
  border-radius: 10px; /* 圆角 */
  background-color: #ec5b56; /* 使用主题色 */
}

/* 动画效果 */
.fade-in-enter-active, .fade-in-leave-active {
  transition: opacity 0.5s ease;
}
.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
}

.slide-in-enter-active, .slide-in-leave-active {
  transition: transform 0.5s ease;
}
.slide-in-enter, .slide-in-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-up-enter, .slide-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

/* 工人信息卡片名片样式 */
.worker-card {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.worker-card-content {
  display: flex;
  align-items: center;
}

.worker-image {
  width: 90px;
  height: 120px;
  border-radius: 8px;
  margin-right: 15px;
  object-fit: cover;
}

.worker-details {
  flex: 1;
  width: 100%!important;
}

.worker-name {
  font-size: 18px;
  text-align: left;
  color: #ec5b56;
  font-weight: bold;
  margin-bottom: 5px;
}

.worker-field, .worker-wait-time {
  font-size: 14px;
  color: #666;
}

/* 订单超时提示 */
.overdue-alert {
  background-color: #fef5f5;
  border: 1px solid #ec5b56;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
  color: #ec5b56;
  font-weight: bold;
  border-radius: 10px;
}

/* 按钮样式 */
.van-button {
  margin-bottom: 15px;
}

.primary-button,
.rate-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ec5b56;
  color: #fff;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.primary-button:hover,
.rate-button:hover {
  background-color: #d94a45;
}

</style>
