<template>
  <div class="container">
    <!-- 订单信息 -->
    <div class="order-card">
      <h2 class="order-title">订单详情</h2>
      <van-divider>维修信息</van-divider>
      <!-- 突出显示的订单信息 -->
      <div class="highlight-section">
        <div class="detail-item highlight">
          <span class="label highlight-label">服务类型:</span>
          <span class="value highlight-value">{{ order.service_type }}</span>
        </div>
        <div class="detail-item highlight">
          <span class="label highlight-label">问题描述:</span>
          <span class="value highlight-value" style="font-size: 16px">{{ order.question_desc }}</span>
        </div>
      </div>
      <van-divider>其他订单信息</van-divider>
      <!-- 其他订单详细信息 -->
      <div class="order-details">
        <div class="detail-item">
          <span class="label">客户姓名:</span>
          <span class="value">{{ order.customer_name }}</span>
        </div>
        <div class="detail-item">
          <span class="label">服务类型详情:</span>
          <span class="value">{{ order.question_type || '未指定' }}</span>
        </div>
        <div class="detail-item">
          <span class="label">预约日期:</span>
          <span class="value">{{ order.appointment_date }}</span>
        </div>
        <div :class="{'overdue': isOverdue}" class="detail-item">
          <span class="label">订单状态:</span>
          <span class="value">{{ orderStatus(order.status) }}</span>
        </div>
        <div class="detail-item">
          <span class="label">工人 ID:</span>
          <span class="value">{{ order.worker_id }}</span>
        </div>
      </div>

      <!-- 已等待时间显示 -->
      <div :style="{ color: isOverdue ? themeColor : themeColor50 }" class="waiting-time">
        <p v-if="waitingTimeInHours > 0">已等待 {{ waitingTimeInHours }} 小时</p>
        <p v-else>已等待不足 1 小时</p>
      </div>

      <!-- 提示信息，当等待时间超过一小时 -->
      <transition name="fade">
        <div v-if="isOverdue" class="reminder">
          <van-icon color="#f56c6c" name="warning-o" size="20px" style="padding-right: 0px;"/>
          <p>已等待超过 1 小时，请尽快完成维修！</p>
        </div>
      </transition>
      <van-divider>维修单填写</van-divider>
      <div class="shangci">
        <div style="display: flex; align-items: center; justify-content: center; color: #ec5b56; padding-bottom: 20px">
          <van-icon name="underway-o" size="20" style="padding-right: 0px;"/>
          <p style="margin: 0; font-weight: bold; font-size: large">最近一次填写</p>
        </div>
        <div class="order-details">
          <div class="detail-item">
            <span class="label">检查类型:</span>
            <span class="value">{{ order.checeked_type || '尚未填写' }}</span>
          </div>
          <div class="detail-item">
            <span class="label">检查结果:</span>
            <span class="value">{{ order.jianchajieguo || '尚未填写' }}</span>
          </div>
          <div class="detail-item">
            <span class="label">维修方案:</span>
            <span class="value">{{ order.weixiufangan || '尚未填写' }}</span>
          </div>
          <div :class="{'overdue': isOverdue}" class="detail-item">
            <span class="label">预计完成时间:</span>
            <span class="value">{{ order.yujiwanchengshijian || '尚未填写' }}</span>
          </div>
        </div>
      </div>

      <!-- 填写检查信息部分 -->
      <div class="form-section">
        <h3 class="section-title">填写检查信息</h3>

        <!-- 新增检查类型的选择 -->
        <van-field
            v-model="selectedCheckTypeText"
            label="检查类型"
            placeholder="请选择检查类型"
            readonly
            required
            size="large"
            @click="showPopup = true"
        />
        <!-- 弹出的选择框 -->
        <van-popup v-model="showPopup" position="bottom" round>
          <van-picker
              :columns="checkOptions"
              show-toolbar
              @cancel="showPopup = false"
              @confirm="onPickerConfirm"
          />
        </van-popup>

        <!-- 当选择“其他”时显示的自定义输入框 -->
        <van-field
            v-if="showOtherInput"
            v-model="selectedCheckTypeTextBackup"
            label="自定义检查类型"
            placeholder="请输入检查类型"
            required
            size="large"
        />
        <van-field
            v-model="jianchajieguo"
            autosize
            label="检查结果"
            placeholder="请输入检查结果"
            required
            size="large"
            type="textarea"
            @click="checkOrderStatus"
        />
        <van-field
            v-model="weixiufangan"
            autosize
            label="维修方案"
            placeholder="请输入维修方案"
            required
            size="large"
            type="textarea"
            @click="checkOrderStatus"
        />

        <!-- 点击后弹出时间选择器 -->
        <van-field
            :value="formattedCompletionTime"
            label="预计完成时间"
            placeholder="请选择预计完成时间"
            readonly
            required
            size="large"
            @click="onFieldClick"
        />
        <van-datetime-picker
            v-if="showPicker"
            v-model="estimatedCompletion"
            :formatter="formatter"
            :max-date="maxDate"
            :min-date="minDate"
            title="选择预计完成时间"
            type="datetime"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />

        <!-- 提交按钮 -->
        <van-button
            v-if="!formSubmitted"
            block
            class="submit-btn"
            round
            type="primary"
            @click="submitInspection"
        >提交
        </van-button>
        <van-button
            v-if="formSubmitted"
            block
            class="submit-btn"
            round
            type="primary"
            @click="correct"
        >点击修改
        </van-button>
        <van-button
            block
            color="#ec5b5c"
            plain
            round
            style="margin-top: 10px"
            type="info"
            @click="fanhui"
        >返回
        </van-button>
      </div>

      <van-divider v-if="formSubmitted===true">右划以完成订单</van-divider>
      <!-- 已用时间及滑动确认，表单提交后显示 -->
      <slide v-if="formSubmitted" :orderId="orderId" style="width: auto;"></slide>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  overflow-x: hidden;
}

.order-card {
  margin-top: -20px;
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 95%;
  max-width: 500px;
  margin-bottom: 20px;
}

.order-title {
  font-size: 23px;
  color: #ec5b56;
  font-weight: bold;
  margin-bottom: 15px;
}

.order-details {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.label {
  flex-shrink: 0;
  font-weight: bold;
  color: #666;
  white-space: nowrap;
}

.value {
  padding-left: 10px;
  flex-grow: 1;
  color: #333;
  text-align: right;
  white-space: normal; /* 允许换行 */
  word-break: break-word; /* 长单词换行 */
}

.highlight-section {
  margin-bottom: 20px;
}

.highlight-label {
  font-size: 18px;
  font-weight: bold;
}

.highlight-value {
  font-size: 20px;
  font-weight: bold;
  color: #ec5b56;
}

.section-title {
  font-size: 18px;
  color: #ec5b56;
  margin-bottom: 10px;
}

.waiting-time {
  font-size: 16px;
  margin-top: 10px;
}

.reminder {
  background-color: #ffe9e9;
  color: #d9534f;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-icon {
  margin-right: 10px;
}

.submit-btn {
  margin-top: 20px;
  background-color: #ec5b56;
  border: none;
}

.submit-btn:hover,
.submit-btn:focus {
  background-color: #d14c47;
}

.overdue {
  color: #d9534f;
}

/* 其他自定义样式 */
.shangci .order-details {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
}

/* 深度选择器覆盖 Vant 样式 */
::v-deep .van-picker__confirm,
::v-deep .van-picker__cancel {
  color: #969799 !important; /* 灰色 */
}

::v-deep .van-picker__title {
  color: #ec5b56 !important; /* 主题色 */
}
</style>
<script>
/* eslint-disable */
import {format, differenceInHours} from "date-fns";
import instance from '../axios';
import slide from "@/components/slide.vue";
import {Dialog} from 'vant';  // 引入 vant 的 Dialog 组件

export default {
  components: {
    slide
  },
  data() {
    return {
      isFinished: false,
      orderId: '',
      selectedCheckTypeTextBackup: '',
      showOtherInput: false,
      order: {},
      jianchajieguo: '',
      weixiufangan: '',
      estimatedCompletion: new Date(),
      formattedCompletionTime: '',
      minDate: new Date(),
      maxDate: new Date(new Date().getFullYear() + 1, 11, 31),
      showPicker: false,
      showPopup: false,  // 控制弹出选择器的显示与隐藏
      formSubmitted: false,
      waitingTimeInHours: 0,
      isOverdue: false,
      themeColor: '#ec5b56',  // 主颜色
      themeColor50: '#ec5b5680',  // 50%透明度的主颜色
      selectedCheckTypeValue: '',  // 保存检查类型的值
      selectedCheckTypeText: '',  // 显示选中的检查类型文本
      checkOptions: [
        {
          text: '传动系统',
          children: [
            {text: '补胎', value: '补胎'},

            {text: '普通轮胎', value: '普通轮胎'},
            {text: '气门嘴', value: '气门嘴'},
            {text: '前轮轴承', value: '前轮轴承'},
            {text: '后轮轴承', value: '后轮轴承'},
            {text: '外球笼', value: '外球笼'},
            {text: '半轴总成', value: '半轴总成'},
            {text: '传动轴', value: '传动轴'},
            {
              text: '其他', // 添加“其他”选项
              value: 'other'
            },
          ]
        },
        {
          text: '刹车系统',
          children: [
            {text: '刹车养护', value: '刹车养护'},
            {text: '刹车油', value: '刹车油'},
            {text: '刹车前片', value: '刹车前片'},
            {text: '刹车后片', value: '刹车后片'},
            {text: '刹车盘', value: '刹车盘'},
            {text: '刹车油管', value: '刹车油管'},
            {text: '刹车分泵', value: '刹车分泵'},
            {text: '刹车总泵', value: '刹车总泵'},
            {text: 'ABS泵', value: 'ABS泵'},
            {
              text: '其他', // 添加“其他”选项
              value: 'other'
            },
          ]
        },
        {
          text: '底盘悬挂系统',
          children: [

            {text: '前减振器', value: '前减振器'},
            {text: '后减震器', value: '后减震器'},
            {text: '减震器附件', value: '减震器附件'},
            {text: '下支臂', value: '下支臂'},
            {text: '横拉杆球头', value: '横拉杆球头'},
            {text: '外球笼', value: '外球笼'},
            {text: '平衡杆拉杆', value: '平衡杆拉杆'},
            {text: '平衡杆胶套', value: '平衡杆胶套'},
            {text: '平衡杆', value: '平衡杆'},
            {text: '后平衡杆拉杆', value: '后平衡杆拉杆'},
            {text: '前元宝梁', value: '前元宝梁'},
            {text: '发动机胶垫', value: '发动机胶垫'},
            {text: '变速箱胶垫', value: '变速箱胶垫'},
            {text: '后拉臂', value: '后拉臂'},
            {text: '后元宝梁', value: '后元宝梁'},

            {
              text: '其他', // 添加“其他”选项
              value: 'other'
            },
          ]
        },
        {
          text: '点火/配气系统',
          children: [
            {text: '电瓶', value: '电瓶'},
            {text: '火花塞（拆进气管除外）', value: '火花塞（拆进气管除外）'},
            {text: '点火线圈', value: '点火线圈'},
            {text: '喷油嘴（免拆进气管）', value: '喷油嘴（免拆进气管）'},
            {text: '起动机', value: '起动机'},
            {text: '正时组件', value: '正时组件'},
            {text: '钥匙电池', value: '钥匙电池'},
            {text: '节气门', value: '节气门'},
            {text: '钥匙电池', value: '钥匙电池'},
            {
              text: '其他', // 添加“其他”选项
              value: 'other'
            },
          ]
        },
        {
          text: '常规养护',
          children: [
            {text: '检查故障', value: '检查故障'},
            {text: '机油机滤', value: '机油机滤'},
            {text: '轮胎补气', value: '轮胎补气'},
            {text: '添加玻璃水', value: '添加玻璃水'},
            {text: '刹车灯泡', value: '刹车灯泡'},
            {text: '清洗节气门', value: '清洗节气门'},
            {text: '清洗喷油嘴', value: '清洗喷油嘴'},
            {text: '清洗三元催化', value: '清洗三元催化'},
            {text: '清洗进气道', value: '清洗进气道'},
            {text: '添加油水', value: '添加油水'},
            {text: '清洗缸内积碳', value: '清洗缸内积碳'},

            {text: '空气滤', value: '空气滤'},
            {text: '空调滤', value: '空调滤'},

            {text: '汽油滤(内置)', value: '汽油滤(内置)'},

            {
              text: '其他', // 添加“其他”选项
              value: 'other'
            },
          ]
        },
      ]

    };
  },
  created() {
    this.orderId = this.$route.params.orderId;
    this.fetchOrderDetails()

  },

  methods: {
    fetchOrderDetails() {
      instance.get(`/api/orders/${this.orderId}`)
          .then((response) => {
            this.order = response.data.data;
            this.calculateWaitingTime(); // 计算等待时间
            console.log(this.order.yujiwanchengshijian);
            if (response.data.data.yujiwanchengshijian !== '' && response.data.data.yujiwanchengshijian !== null) {
              console.log('test')
              this.formSubmitted = true
            }
            console.log(this.formSubmitted);
          })
          .catch((error) => {
            console.error("获取订单信息失败", error);
          });
    },
    fanhui() {
      this.$router.go(-1);
    },
    calculateWaitingTime() {
      const appointmentDate = new Date(this.order.appointment_date);
      const now = new Date();
      this.waitingTimeInHours = differenceInHours(now, appointmentDate);

      if (this.waitingTimeInHours >= 1) {
        this.isOverdue = true;  // 超过1小时
      }
    },
    orderStatus(status) {
      switch (status) {
        case 1:
          return "待处理";
        case 2:
          return "正在维修";
        case 3:
          return "已完成";
        default:
          return "未知状态";
      }
    },
    formatter(type, value) {
      if (type === "year") return `${value}年`;
      if (type === "month") return `${value}月`;
      if (type === "day") return `${value}日`;
      if (type === "hour") return `${value}时`;
      if (type === "minute") return `${value}分`;
      return value;
    },
    onConfirm(value) {
      this.estimatedCompletion = value;
      this.formattedCompletionTime = format(value, "yyyy-MM-dd HH:mm");
      this.showPicker = false;
    },
    onFieldClick() {
      if (this.order.status === 3) {
        this.showCompletionDialog();
      } else {
        this.showPicker = true;  // 正常打开时间选择器
      }
    },
    onPickerConfirm(value, index) {
      console.log(value[1]);
      // value 是选择的子选项文本，判断是否为 '其他'
      if (value[1] === '其他') {
        this.showOtherInput = true;  // 显示自定义输入框
        this.selectedCheckTypeText = value;  // 保存选择的文本
        this.selectedCheckTypeValue = value;  // 保存选择的值
      } else {
        this.showOtherInput = false;  // 隐藏自定义输入框
        this.selectedCheckTypeText = value;  // 保存选择的文本
        this.selectedCheckTypeValue = value;  // 保存选择的值
      }
      console.log(this.selectedCheckTypeValue);
      this.showPopup = false;  // 关闭选择框
    },
    correct() {
      this.formSubmitted = false
    },
    submitInspection() {
      var selectedCheckTypeText = this.selectedCheckTypeText.split(',')[0];
      if (this.selectedCheckTypeTextBackup === '') {

        var selectedCheckTypeTextSubmit = selectedCheckTypeText + ',' + this.selectedCheckTypeValue[1]

      } else {
        var selectedCheckTypeTextSubmit = selectedCheckTypeText + ',' + this.selectedCheckTypeTextBackup
      }

      console.log(selectedCheckTypeText)
      if (this.selectedCheckTypeText && this.jianchajieguo && this.weixiufangan && this.estimatedCompletion) {
        const requestData = {
          checkType: selectedCheckTypeTextSubmit, // 使用自定义文本或选择的文本
          jianchajieguo: this.jianchajieguo,
          weixiufangan: this.weixiufangan,
          yujiwanchengshijian: this.estimatedCompletion,
        };

        instance.post(`/api/orders/${this.orderId}/update-inspection`, requestData)
            .then(response => {
              console.log(response);
              this.$toast.success('检查信息提交成功');
              this.formSubmitted = true;
              this.fetchOrderDetails();
            })
            .catch(error => {
              console.error("提交检查信息失败", error);
              this.$toast.fail('提交失败，请稍后再试');
            });
      } else {
        this.$toast.fail('请填写所有信息');
      }
    },
    showCompletionDialog() {
      Dialog.alert({
        title: '订单已完成',
        message: '此订单已完成，无法进行修改或提交。',
      });
    }
  }
};
</script>