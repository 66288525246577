<template>
  <div class="container">
    <transition name="fade">
      <div class="login">
        <div class="content">
          <img src="https://pan.imgbed.link/file/35007" alt="Logo" class="logo" />
          <h2 class="title">登录</h2>
          <p class="description">
            正在为您重定向，请稍候...
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  mounted() {
    // 页面加载后自动重定向到login页面
    setTimeout(() => {
      this.$router.push('/login');
    }, 2000);  // 2秒后重定向
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.content {
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ec5b56;
}

.description {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

/* 过渡动画 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* 媒体查询确保移动端适配 */
@media (max-width: 600px) {
  .content {
    padding: 20px;
  }

  .title {
    font-size: 20px;
  }
}
</style>
