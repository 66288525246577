<template>
  <div class="container">
    <!-- 循环店铺 -->
    <div v-for="(store, storeId) in ordersGrouped" :key="storeId" class="store-section">
      <!-- 店铺标题 -->
      <h3 class="store-title">
        <p v-if="storeId === '1'">奥达汽车服务有限公司</p>
        <p v-if="storeId === '2'">奥达汽车维修中心</p>
      </h3>

      <!-- 循环技师 -->
      <div v-for="(worker, workerId) in store.workers" :key="workerId" class="worker-section">
        <!-- 工人名字 -->
        <div class="worker-name-container" @click="toggleWorker(workerId)">
          <h4 class="worker-name">{{ worker.worker_name }}</h4>
          <van-icon :name="expandedWorkerId === workerId ? 'arrow-up' : 'arrow-down'" class="arrow-icon" />
        </div>

        <!-- 展开后显示工人卡片 -->
        <transition name="fade">
          <div v-if="expandedWorkerId === workerId" class="worker-card">
            <div class="worker-info">
              <img :src="worker.worker_image" alt="工人照片" class="worker-image" />
            </div>
            <div class="worker-orders-info">
              <div class="count-item">
                <span style="font-size: 18px;color: #ec5b56;font-weight: bold" class="count-title">未处理订单:</span>
                <span style="font-size: 18px;color: #ec5b56;font-weight: bold" class="count-value ">{{ worker.unprocessed_count }}</span>
              </div>
              <div class="count-item">
                <span style="font-size: 16px;color: #ec5b5680;font-weight: bold" class="count-title">进行中的订单:</span>
                <span style="font-size: 16px;color: #ec5b5680;font-weight: bold" class="count-value ">{{ worker.in_progress_count }}</span>
              </div>
              <div class="count-item">
                <span  style="font-size: 14px;color: #ec5b5660;font-weight: bold" class="count-title">已完成订单:</span>
                <span style="font-size: 14px;color: #ec5b5660;font-weight: bold" class="count-value ">{{ worker.completed_count }}</span>
              </div>
            </div>

            <!-- 展开的订单列表 -->
            <transition-group name="fade" tag="div" class="orders-list">
              <div v-for="order in sortedOrders(worker.orders)" :key="order.id" class="order-card">
                <!-- 订单摘要 -->
                <div class="order-summary" >
                  <div class="order-header">
                    <h4 class="order-title">订单摘要</h4>
                    <!-- 二维码 -->
                    <div class="qr-code-section">
                      <img :src="order.qrCodeDataURL" alt="二维码" class="qr-code" />
                      <p class="small-text">扫码查看PDF检查单</p>
                    </div>
                  </div>
                  <div class="order-item">
                    <span class="order-title">客户姓名:</span>
                    <span class="order-value">{{ order.customer_name || '匿名用户' }}</span>
                  </div>
                  <div class="order-item">
                    <span class="order-title">服务类型:</span>
                    <span class="order-value">{{ order.service_type }}</span>
                  </div>
                  <div class="order-item">
                    <span class="order-title">需求描述:</span>
                    <span class="order-value">{{ order.question_desc || '未知需求' }}</span>
                  </div>
                  <div class="order-item">
                    <span class="order-title">预计完成时间:</span>
                    <span class="order-value">{{ order.yujiwanchengshijian || '未设置' }}</span>
                  </div>

                  <!-- 显示订单状态 -->


                  <!-- 已用时间 -->
                  <div class="order-item" v-if="order.status !== 3 && order.status !== 4 ">
                    <span class="order-title">已用时间:</span>
                    <span style="padding: 3px 6px;background: rgba(236, 91, 86, 0.7);border-radius: 10px;font-size: 14px;color: white;font-weight: bold" :class="{'over-one-hour': getElapsedTime(order.appointment_date) > 1}" class="order-value">
                      已超过1小时
                    </span>
                  </div>
 <div class="order-status-tag" :class="getOrderStatusClass(order.status)">
                    {{ getOrderStatusText(order.status) }}
                  </div>
                  <div v-if="order.status === 2" @click="handleComplete(order.id)" style="margin: 10px 0" class="order-finish">
                    完成订单
                  </div>
<!--                   <van-button style="margin: 10px 0" v-if="order.status===2" color="#eb5c56" block round plain>完成订单</van-button>-->
                  <!-- 展开/收起文案切换 -->
                  <p @click="toggleOrderDetails(order.id)" class="click-details">
                    {{ expandedOrderId === order.id ? '再次点击收起订单详情' : '点击查看订单详情' }}
                  </p>
                </div>

                <!-- 点击后显示订单详细信息 -->
                <transition name="fade">
                  <div v-if="expandedOrderId === order.id" class="order-details">
                    <div class="order-item">
                      <span class="order-title">客户电话:</span>
                      <span class="order-value">{{ order.customer_phone }}</span>
                    </div>
                    <div class="order-item">
                      <span class="order-title">预约日期:</span>
                      <span class="order-value">{{ order.appointment_date }}</span>
                    </div>
                    <div class="order-item">
                      <span class="order-title">问题描述:</span>
                      <span class="order-value">{{ order.question_desc }}</span>
                    </div>
                    <div class="order-item">
                      <span class="order-title">预检问题类型:</span>
                      <span class="order-value">{{ order.checked_type || '未知类型' }}</span>
                    </div>
                    <div class="order-item">
                      <span class="order-title">检查结果:</span>
                      <span class="order-value">{{ order.jianchajieguo }}</span>
                    </div>
                    <div class="order-item">
                      <span class="order-title">维修方案:</span>
                      <span class="order-value">{{ order.weixiufangan }}</span>
                    </div>
                    <div class="order-item">
                      <span class="order-title">预计完成时间:</span>
                      <span class="order-value">{{ order.yujiwanchengshijian }}</span>
                    </div>
                  </div>
                </transition>
              </div>
            </transition-group>
          </div>
        </transition>
      </div>
    </div>
    <van-button block color="#ec5b56" plain round @click="goBack">返回登录</van-button>
  </div>
</template>

<script>

import QRCode from 'qrcode';
import instance from "../axios";

export default {
  data() {
    return {
      ordersGrouped: {}, // 按店铺和工人分组的订单数据
      expandedWorkerId: null,  // 控制展开的工人订单列表
      expandedOrderId: null,   // 控制展开的订单详细信息
      customQRCodePart: `http://8.130.116.200:8081/generate_pdf?order_id=`, // 自定义二维码内容部分
    };
  },
  methods: {
    goBack(){
      this.$router.push('/login')
    },
    fetchOrders() {
      const token = localStorage.getItem('token');
      instance
        .get('/api/admin/orders', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.groupOrdersByStoreAndWorker(response.data.data);
          this.generateQRCodesAndWarnings(); // 确保数据加载后立即生成二维码和超时提醒
        })
        .catch((error) => {
          this.$toast.fail('获取订单失败');
          console.error(error);
        });
    },
    groupOrdersByStoreAndWorker(data) {
      this.ordersGrouped = {};
      data.forEach((worker) => {
        if (!this.ordersGrouped[worker.worker_store]) {
          this.ordersGrouped[worker.worker_store] = { workers: {} };
        }
        if (!this.ordersGrouped[worker.worker_store].workers[worker.worker_id]) {
          this.ordersGrouped[worker.worker_store].workers[worker.worker_id] = {
            worker_name: worker.worker_name,
            worker_image: worker.worker_image,
            in_progress_count: worker.in_progress_count,
            completed_count: worker.completed_count,
            unprocessed_count: worker.unprocessed_count,
            orders: worker.orders,
          };
        }
      });
    },
    handleComplete(orderId) {
      // 滑动完成后的操作
      this.$toast.success('订单已完成！');
      console.log(orderId);
      instance.post(`/api/orders/${orderId}/complete`)
    .then(response => {
        console.log(response.data.message);
        this.fetchOrders()
    })
    .catch(error => {
        console.error(error.response.data.error);
    });

    },
    generateQRCodesAndWarnings() {
      // 遍历订单并生成二维码和超时提醒
      Object.values(this.ordersGrouped).forEach((store) => {
        Object.values(store.workers).forEach((worker) => {
          worker.orders.forEach((order) => {
            // 生成二维码
            const qrCodeContent = `${this.customQRCodePart}${order.id}`;
            QRCode.toDataURL(qrCodeContent, { color: { dark: '#ec5b56', light: '#ffffff' }, width: 100 }, (err, url) => {
              if (!err) {
                this.$set(order, 'qrCodeDataURL', url); // 将生成的二维码绑定到订单数据
              } else {
                console.error('生成二维码失败:', err);
              }
            });
          });
        });
      });
    },
    getElapsedTime(appointmentDate) {
      const currentTime = new Date();
      const appointmentTime = new Date(appointmentDate);
      const elapsedHours = Math.floor((currentTime - appointmentTime) / (1000 * 60 * 60));
      return elapsedHours;
    },
    toggleWorker(workerId) {
      this.expandedWorkerId = this.expandedWorkerId === workerId ? null : workerId;
    },
    toggleOrderDetails(orderId) {
      this.expandedOrderId = this.expandedOrderId === orderId ? null : orderId;
    },
    sortedOrders(orders) {
      const statusOrder = { 2: 1, 1: 2, 3: 3 }; // 优先级：未处理 > 进行中 > 已完成
      return orders.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
    },
    getOrderStatusClass(status) {
      if (status === 1) return 'unprocessed';
      if (status === 2) return 'in-progress';
      if (status === 3) return 'completed';
      if (status === 4) return 'completed';
    },
    getOrderStatusText(status) {
      if (status === 1) return '未处理';
      if (status === 2) return '进行中';
      if (status === 3) return '已完成';
      if (status === 4) return '已完成';
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android/.test(userAgent);

      // 如果是PC端，则跳转到另一个页面
      if (!isMobile) {
        this.$router.push('/guanliyuanxiangqingpc');
      }
    },
  },
  mounted() {
    this.checkDeviceType();
    this.fetchOrders();
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
  background-color: #f9f9f9;
}

/* 店铺标题样式 */
.store-section {
  margin-bottom: 20px;
}

.store-title {
  font-size: 22px;
  font-weight: bold;
  color: #ec5b56;
  margin-bottom: 15px;
  border-bottom: 2px solid #ec5b56;
  padding-bottom: 10px;
}

/* 工人卡片样式 */
.worker-section {
  margin-bottom: 20px;
}

.worker-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.worker-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.arrow-icon {
  color: #666;
}
.order-value{
  margin-left: 20px;
  text-align: right;
  font-size: 16px;
  color: #333333;
}
.worker-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.worker-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.worker-image {
  width: 60px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 5px;
}

/* 订单状态的不同字号和颜色 */
.worker-orders-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.count-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.unprocessed {
  color: #ec5b56;
  font-size: 18px;
  font-weight: bold;
}

.in-progress {
  color: rgba(236, 91, 86, 0.7);
  font-size: 16px;
  font-weight: normal;
  color: white;
}

.completed {
  color: rgba(236, 91, 86, 0.5);
  font-size: 14px;
  font-weight: lighter;
}

.click-to-view {
  font-size: 12px;
  color: #666;
  text-align: right;
  margin-top: 10px;
}

/* 订单卡片样式 */
.orders-list {
  padding-left: 0;
}

.order-card {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.order-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* 二维码样式 */
.qr-code-section {
  text-align: right;
}

.qr-code {
  width: 50px;
  height: 50px;
}

.small-text {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

/* 订单详情样式 */
.order-title {
  white-space: nowrap;
  font-size: 16px;
  font-weight: normal;
  color: #999;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-status-tag {
  margin-top: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.order-finish {
  color: #ec5b56;

  border: 1px solid #ec5b56 !important;
  margin-top: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
/* 状态颜色 */
.completed {
  background-color: rgba(236, 91, 86, 0.5);
  color: white;
}

.in-progress {
  background-color: rgba(236, 91, 86, 0.7);
}

.unprocessed {
  background-color: #ec5b56;
  color: white;
}

.click-details {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
  text-align: right;
}

/* 动画效果 */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
