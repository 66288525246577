<template>
  <div class="user-info-item">
    <div class="left-section">
      <img class="photo" :src="photo" alt="一寸照片" />
    </div>
    <div class="right-section">
      <h3 class="name">{{ name }}</h3>
      <p class="wait-time">{{ waitTime }}</p>
      <van-rate color="#ef7f7b" :value="internalRate" :count="5" :size="18" readonly />
      <div class="tags">
        <van-tag
          size="large"
          v-for="(tag, index) in internalTags"
          :key="index"
          type="primary"
          class="field-tag"
          :color="themeColor"
        >
          {{ tag }}
        </van-tag>
      </div>
      <van-progress :percentage="progress" :color="themeColor" show-pivot="false"></van-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfoDisplay',
  props: {
    photo: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    waitTime: {
      type: String,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    rate: {
      type: Number,
      required: true
    },
    tags: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      internalRate: this.rate,
      internalTags: this.tags.split('/'),
      themeColor: '#ef7f7b'
    };
  }
}
</script>

<style scoped>
.user-info-item {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 15px;
  align-items: center;
}

.left-section {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 90px;
  height: 120px;
  border-radius: 10%;
}

.right-section {
  width: 75%;
  padding-left: 10px;
}

.name {
  margin: 0;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.wait-time {
  margin: 5px 0;
  color: #666;
}

.field-tag {
  margin-right: 5px;
  margin-top: 5px;
}

.van-progress {
  margin-top: 10px;
  height: 20px;
  border-radius: 5px;
}

.van-progress__pivot {
  display: none !important;
}

/* 添加移动端适配 */
@media (max-width: 768px) {
  .user-info-item {
    flex-direction: column;
    padding: 10px;
    align-items: flex-start;
  }

  .left-section {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .right-section {
    width: 100%;
    padding-left: 0;
  }

  .photo {
    width: 70px;
    height: 90px;
  }

  .name {
    font-size: 16px;
  }

  .wait-time {
    font-size: 14px;
  }

  .van-progress {
    height: 15px;
  }

  .field-tag {
    font-size: 12px;
    margin-top: 3px;
  }
}
</style>
