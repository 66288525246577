<template>
  <div class="worker-selection-page">
    <!-- 加载动画 -->
    <div v-if="loading" class="loading-container">
      <div class="win10-loading">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
      <p class="loading-text">正在为您智能推荐，请稍后</p>
    </div>

    <!-- 推荐维修师傅信息 -->
    <div v-else class="content-center">
      <h1 class="title">为您推荐的维修师傅</h1>

      <div v-if="workers.length" class="cards-container">
        <div
          v-for="(worker, index) in workers"
          :key="index"
          class="worker-card"
          @click="selectWorker(worker)"
          :class="{ selected: selectedWorker && selectedWorker.worker_id === worker.worker_id }"
        >
          <!-- 照片放在最上面 -->
          <img :src="worker.worker_image" alt="师傅照片" class="worker-image" />

          <!-- 工人详细信息，瀑布流排列 -->
          <div style="width: 100%!important;" class="worker-details">
            <p class="worker-name">{{ worker.worker_name }}</p>

            <!-- 擅长领域标签 -->
            <div class="worker-field">
              <van-tag
                v-for="(field, index) in worker.worker_field.split('/')"
                :key="index"
                color="#ec5b56"
                size="large"
                style="margin-right: 8px; font-size: 16px; border-radius: 5px;"
              >
                {{ field }}
              </van-tag>
            </div>

            <!-- 评分显示为数字 -->
           <div class="worker-rating">
  <van-rate
    v-model="worker.worker_rate"
    :count="5"
    :size="22"
    readonly
    allow-half
    void-icon-color="#ececec"
    :color="'#ec5b56'"
    class="worker-rate"
  ></van-rate>
</div>

            <!-- 预计等待时间 -->
            <p class="worker-wait-time"> {{ worker.estimated_wait_time }}</p>

            <!-- 维修进度，使用进度条 -->
            <div class="worker-progress">
              <p class="progress-label">维修进度</p>
              <van-progress
                :percentage="worker.process"
                color="#ec5b56"
                :stroke-width="20"
                show-pivot
              />
            </div>
          </div>
        </div>

        <!-- 操作按钮 -->
        <div class="action-buttons">
          <van-button
            type="primary"
            :color="'#ec5b56'"
            @click="confirmWorker"
            block
            :disabled="!selectedWorker"
          >
            继续使用此师傅
          </van-button>
          <van-button type="default" @click="manualSelect" block>手动选择其他师傅</van-button>
        </div>
      </div>

      <div v-else>
        <van-empty description="暂无推荐师傅" />
      </div>
    </div>
  </div>
</template>
<script>
import instance from '@/axios';  // 引入配置好的 Axios 实例
import { Toast, Tag, Progress } from 'vant';  // 引入 van-progress、van-tag 组件

export default {
  components: {
    'van-tag': Tag,
    'van-progress': Progress,
  },
  props: ['shopId'],  // 从路由参数接收 shop_id
  data() {
    return {
      workers: [],
      selectedWorker: null,
      loading: true, // 加载状态
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
      this.fetchTwoWorkers();
    }, 3000);
  },
  methods: {
    async fetchTwoWorkers() {
      try {
        const response = await instance.get(`/api/shops/${this.shopId}/workers`);
        if (response.data.status === 'success' && response.data.data.length) {
          this.workers = response.data.data.sort((a, b) => {
            const waitTimeA = parseInt(a.estimated_wait_time.match(/\d+/)[0]);
            const waitTimeB = parseInt(b.estimated_wait_time.match(/\d+/)[0]);
            return waitTimeA - waitTimeB;
          }).slice(0, 2);
        } else {
          Toast.fail(response.data.message || '未找到合适的师傅');
        }
      } catch (error) {
        Toast.fail('获取师傅信息失败');
      }
    },
    selectWorker(worker) {
      this.selectedWorker = worker;
    },
    confirmWorker() {
      if (this.selectedWorker) {
        Toast.success(`已选择${this.selectedWorker.worker_name}`);
        const userId = this.selectedWorker.worker_id;
        this.$router.push({
          path: '/yuyuexiangqing',
          query: { user_id: userId },
        });
      }
    },
    manualSelect() {
      this.$router.push(`/homepage`);
    },
    calculateExperience(created_at) {
      const startYear = new Date(created_at).getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - startYear;
    },
  },
};
</script>
<style scoped>
/* 主容器 */
.worker-selection-page {
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

/* 加载动画容器 */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Win10 加载动画 */
.win10-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.circle {
  width: 18px;
  height: 18px;
  background-color: #ec5b56;
  border-radius: 50%;
  animation: win10-spin 1s infinite ease-in-out;
}

.circle:nth-child(1) { animation-delay: 0s; }
.circle:nth-child(2) { animation-delay: 0.1s; }
.circle:nth-child(3) { animation-delay: 0.2s; }
.circle:nth-child(4) { animation-delay: 0.3s; }
.circle:nth-child(5) { animation-delay: 0.4s; }

@keyframes win10-spin {
  0%, 100% { transform: scale(0.8); }
  50% { transform: scale(1.2); }
}

/* 加载提示文字 */
.loading-text {
  margin-top: 20px;
  font-size: 20px;
  color: #666;
  font-weight: 500;
}

/* 内容居中 */
.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* 标题 */
.title {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 700;
}

/* 卡片容器 */
.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
}

/* 工人卡片 */
.worker-card {
  background-color: #fff;
  width: 90%;
  max-width: 450px;
  padding: 14px;
  border-radius: 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.worker-card.selected {
  border: 2px solid #ec5b56;
  box-shadow: 0 6px 18px rgba(236, 91, 86, 0.2);
}

/* 调整图片大小，照片放置顶部 */
.worker-image {
  width: 100px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* 工人姓名，仅姓名使用红色 */
.worker-name {
  font-size: 22px;
  color: #ec5b56;
  font-weight: 700;
  margin-bottom: 10px;
}

/* 标签样式 */
.worker-field {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 6px;
}

.worker-field .van-tag {
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
}

/* 评分显示为数字 */
.worker-rating {

  padding: 4px 0px 0px 0px;
  font-size: 16px;
  color: #333;
  margin-bottom: 6px;
}

/* 其他详细信息 */
.worker-experience,
.worker-wait-time,
.worker-progress {
  font-size: 16px;
  color: #333;
  margin-bottom: 4px;
  font-weight: 600;
}

.worker-wait-time {
  color: #ec5b56;
  font-weight: 600;
}

/* 进度条 */
.worker-progress {
  width: 100%;
  margin-top: 8px;
}

.progress-label {
  font-size: 14px;
  margin-bottom: 4px;
}

/* 操作按钮 */
.action-buttons {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 450px;
  gap: 8px;
}

.van-button {
  width: 100%;
  border-radius: 8px;
  font-weight: bold;
}

.van-button.primary {
  background-color: #ec5b56;
  color: #fff;
}

.van-button.default {
  background-color: #fff;
  border: 1px solid #ec5b56;
  color: #ec5b56;
}

</style>