<template>
  <div>
   <h1 style="text-align: center">此页面已废弃</h1>
    <div class="container">
    <img src="https://pan.imgbed.link/file/35007" class="logo" />

    <div class="form-container">
      <van-toast id="custom-selector" />

      <van-divider content-position="center" custom-style="color: #ec5b5690; border-color: #ec5b5660; font-size: 14px;">
        修改密码
      </van-divider>

      <van-cell-group>
        <van-field
          center
          v-model="oldPassword"
          label="原密码"
          type="password"
          placeholder="请输入原密码"
          :border="false"
        />
        <van-field
          center
          v-model="newPassword"
          label="新密码"
          type="password"
          placeholder="请输入新密码"
          :border="false"
        />
        <van-field
          center
          v-model="confirmNewPassword"
          label="重复新密码"
          type="password"
          placeholder="请重复新密码"
          :border="false"
        />
      </van-cell-group>

       <van-button style="margin-top: 20px" round block type="primary" color="#ec5b56" class="center-button" @click="submit">
      提交修改
    </van-button>
    <van-button style="margin-top: 20px" plain round block type="primary" color="#ec5b56" class="center-button" @click="goBack">
      返回首页
    </van-button>
    </div>

    <div class="footer">
      <div>感谢您选择奥达汽修</div>
      <div>请您确保填写正确的密码信息</div>
    </div>
  </div>
  </div>
</template>

<script>
import { Toast, Field, CellGroup, Button, Divider } from 'vant';
import 'vant/lib/index.css';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  },
  methods: {
    validatePasswords() {
      if (!this.oldPassword || !this.newPassword || !this.confirmNewPassword) {
        Toast.fail('所有字段均为必填项');
        return false;
      }
      if (this.newPassword !== this.confirmNewPassword) {
        Toast.fail('新密码和重复新密码不一致');
        return false;
      }
      return true;
    },
    submit() {
      if (this.validatePasswords()) {
        // 提交密码修改逻辑
        Toast.success('密码修改成功');
      }
    },
  },
  components: {
    [Toast.name]: Toast,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
  },
};
</script>

<style>
.container {
  padding: 10px;
  text-align: center;
  margin-top: 5vh;
}

.logo {
  width: 100px;
  border-radius: 10px;
}

.form-container {
  margin-top: 10px;
  padding: 15px;
}

.submit-button {
  display: block;
  width: 80%;
  margin: 30px auto 0;
  background-color: #ec5b56;
  color: white;
  border-radius: 10px;
  border: none;
}

.footer {
  margin-top: 2vh;
  font-size: 12px;
  text-align: center;
  color: slategray;
}

.footer div {
  margin-top: 0.5vh;
}
</style>