<template>
  <div class="user-info-item">
    <div class="left-section">
      <img class="photo" :src="photo" alt="一寸照片" />
    </div>
    <div class="right-section">
      <h3 class="name">{{ name }}</h3>
      <p class="wait-time">{{ waitTime }}</p>
          <van-rate color="#ef7f7b" :value="rate" :read-only="true" :size="20" />
      <div class="tags">
        <van-tag
          size="large"
          color="#ef7f7b"
          v-for="(tag, index) in tags"
          :key="index"
          type="primary"
          class="tag"
        >
          {{ tag }}
        </van-tag>
      </div>

      <van-progress  pivot-color="#ef7f7b" :percentage="progress" color="#ef7f7b" show-pivot="false" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfoDisplay',
  props: {
    photo: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    waitTime: {
      type: String,
      required: true
    },
    rate: {
      type: Number,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    progressColor: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.user-info-item {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 12px;
  align-items: center;
}

.left-section {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 90px;
  height: 120px;
  border-radius: 10%;
}

.right-section {
  width: 80%;
  padding-left: 8px;
}

.name {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.wait-time {
  margin: 6px 0;
  color: #666;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0;
}

.tag {
  margin-right: 4px;
  margin-bottom: 6px;
}

.van-progress {
  margin-top: 8px;
  height: 18px; /* 调整进度条高度 */
  border-radius: 4px; /* 进度条圆角 */
}
</style>
