<template>
  <div class="orders-page">
    <h1 class="title">我的订单</h1>

    <div v-if="sortedOrders.length">
      <div
        v-for="order in sortedOrders"
        :key="order.id"
        class="order-item"
        :class="{ 'completed-order': order.status === 3|| order.status === 4  }"
        @click=" viewOrderDetails(order.id)"
      >
        <div class="order-header">
          <span class="service-type">{{ order.service_type }}</span>
          <span class="status" :style="{color: order.status === 3|| order.status === 4 ? '#999' : themeColor}">({{ formatStatus(order.status) }})</span>
        </div>
        <div class="order-info">
          <p>预约时间: {{ formatDate(order.appointment_date) }}</p>
          <p>问题描述: {{ order.question_desc }}</p>
        </div>
      </div>
    </div>
    <div v-else class="no-orders">
      <van-empty description="暂无订单" />
    </div>

    <!-- 返回按钮 -->
    <div class="button-container">
      <van-button size="large" type="default" color="#ec5b56" style="border-radius: 15px"  @click="goBack" block>
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
import instance from '@/axios';  // 引入配置好的 Axios 实例
import {Toast} from 'vant';

export default {
  data() {
    return {
      orders: [],
      themeColor: '#ec5b56'  // 主题色
    };
  },
  created() {
    this.fetchOrders();
  },
  computed: {
    // 对订单按照状态排序
    sortedOrders() {
      return [...this.orders].sort((a, b) => a.status - b.status); // 根据 status 进行升序排序：1 -> 2 -> 3
    }
  },
  methods: {
    async fetchOrders() {
      try {
        const response = await instance.get('/api/orders');
        if (response.data.status === 'success') {
          this.orders = response.data.data;
        } else {
          Toast.fail(response.data.message);
        }
      } catch (error) {
        Toast.fail('获取订单失败');
      }
    },
    viewOrderDetails(orderId) {
      this.$router.push(`/dingdanxiangqing/${orderId}`);  // 跳转到订单详情页面
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString();
    },
    formatStatus(status) {
      const statusMap = {
        1: '待处理',
        2: '处理中',
        3: '已完成',
        4:'已完成评价'
      };
      return statusMap[status] || '未知状态';
    },
    goBack() {
      this.$router.push('/homepage');  // 返回上一页
    }
  }
};
</script>

<style scoped>
.orders-page {
  min-height: 100vh; /* 确保页面高度覆盖整个视口 */
  padding: 20px;
  background-color:white; /* 背景色为浅灰色 */
}

.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.no-orders {
  margin-top: 50px;
  text-align: center;
}

.order-item {
  background-color: #fff;
  border-radius: 12px; /* 调整圆角 */
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 加深阴影效果 */
  transition: all 0.3s ease;
}

.order-item:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* 添加悬停效果 */
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.service-type {
  white-space: nowrap;
  font-size: 18px;
  color: #333;
}

.status {
  white-space: nowrap;
  margin-left: 1vw;
  font-size: 16px;
}

.order-info {
  padding-left: 10px;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.order-info p {
  margin: 5px 0;
}

/* 返回按钮容器样式 */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* 按钮与内容留出一些间距 */
}

.back-button {
  width: 90%; /* 设置按钮宽度为 90% */
  background-color: #ec5b56;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding: 12px;
}

/* 已完成的订单应用置灰样式 */
.completed-order {
  background-color: #f5f5f5; /* 背景色为浅灰色 */
  color: #999;
  font-weight: 400; /* 使文字变细 */
  box-shadow: none; /* 移除阴影 */
}

.completed-order:hover {
  box-shadow: none;
  cursor: default; /* 禁止手型指针，视觉上不让用户感觉可以点击 */
}
</style>
