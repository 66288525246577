<template>
  <div class="container">
    <!-- 校验码输入部分 -->
    <transition name="fade">
      <div v-if="!isVerified" class="verification-section">
        <h2 class="title">输入校验码</h2>
        <van-cell-group>
          <van-field
              v-model="verificationCode"
              :error-message="errors.verificationCode"
              clearable
              label="校验码"
              label-width="6rem"
              placeholder="请输入员工注册校验码"
          />
        </van-cell-group>
        <van-button block class="submit-btn" color="#ec5b56" round type="primary" @click="verifyCode">
          校验
        </van-button>
        <van-button block class="submit-btn" color="#ec5b56" round plain @click="goback">
          返回普通用户注册
        </van-button>
      </div>
    </transition>

    <!-- 注册表单部分 -->
    <transition name="fade">
      <div v-if="isVerified" class="register-section">
        <h2 class="title">工人注册</h2>
        <van-cell-group>
          <!-- 姓名 -->
          <van-field
              v-model="name"
              :error-message="errors.name"
              clearable
              label="姓名"
              label-width="6rem"
              placeholder="请输入姓名"
          />

          <!-- 手机号 -->
          <van-field
              v-model="phone"
              :error-message="errors.phone"
              clearable
              label="手机号"
              label-width="6rem"
              placeholder="请输入手机号"
              type="tel"
          />

          <!-- 再次输入手机号 -->
          <van-field
              v-model="phoneConfirm"
              :error-message="errors.phoneConfirm"
              clearable
              label="确认手机号"
              label-width="6rem"
              placeholder="请再次输入手机号"
              type="tel"
          />

          <!-- 新密码 -->
          <van-field
              v-model="password"
              :error-message="errors.password"
              clearable
              label="新密码"
              label-width="6rem"
              placeholder="请输入密码"
              type="password"
          />

          <!-- 确认密码 -->
          <van-field
              v-model="passwordConfirm"
              :error-message="errors.passwordConfirm"
              clearable
              label="确认密码"
              label-width="6rem"
              placeholder="请确认密码"
              type="password"
          />

          <!-- 上传头像 -->
          <van-cell>
            <van-uploader
                v-model="avatar"
                :after-read="onAfterRead"
                :max-count="1"
                class="custom-uploader"
            />
          </van-cell>

          <!-- 归属店铺选择 -->
          <van-field
              v-model="store"
              clickable
              is-link
              label="归属店铺"
              label-width="6rem"
              placeholder="请选择店铺"
              readonly
              @click="showStoreSelect = true"
          />
          <van-popup v-model="showStoreSelect" position="bottom">
            <van-picker
                :columns="storeOptions"
                show-toolbar
                title="选择店铺"
                @cancel="showStoreSelect = false"
                @confirm="onStoreConfirm"
            />
          </van-popup>

          <!-- 擅长领域选择 -->
          <van-field
              readonly
              clearable
              label="擅长领域"
              label-width="6rem"
              placeholder="请选择擅长领域"
          />
          <van-cell title-style="width: 6rem;">
            <div class="tags-section">
              <span
                  v-for="(item, index) in expertiseOptions"
                  :key="index"
                  :class="{'selected': expertise.includes(item)}"
                  class="expertise-tag"
                  @click="selectExpertise(item)"
              >
                {{ item }}
              </span>
            </div>
          </van-cell>
        </van-cell-group>

        <!-- 注册按钮 -->
        <van-button block class="submit-btn" color="#ec5b56" round type="primary" @click="openPopup">
          注册
        </van-button>

        <!-- 确认信息 Popup -->
        <van-popup round v-model="showPopup" closeable position="bottom">
          <div class="popup-content">
            <!-- 照片显示在第一行垂直居中 -->
            <van-cell size="large">
              <div class="avatar-preview">
                <img v-if="avatarUrl" :src="avatarUrl" alt="头像" class="avatar-img"/>
              </div>
            </van-cell>

            <!-- 其他信息展示 -->
            <van-cell-group>
              <van-cell title="姓名" :value="name" size="large" />
              <van-cell title="手机号" :value="phone" size="large" />
              <van-cell title="归属店铺" :value="store" size="large" />
              <van-cell title="擅长领域" :value="expertise.join(', ')" size="large" />
            </van-cell-group>

            <!-- 提示注册后无法修改 -->
            <p class="warning-text">注册后无法修改，请确认信息无误</p>

            <!-- 倒计时注册按钮 -->
            <van-button :disabled="countdown > 0" block color="#ec5b56" round @click="submit">
              {{ countdown > 0 ? `请检查 (${countdown}s)` : '确认注册' }}
            </van-button>
          </div>
        </van-popup>
      </div>
    </transition>
  </div>
</template>

<script>
import OSS from 'ali-oss';
import instance from './../axios';
import CryptoJS from 'crypto-js';
export default {
  data() {
    return {
      isVerified: false,
      verificationCode: '',
      name: '',
      phone: '',
      phoneConfirm: '',
      password: '',
      passwordConfirm: '',
      avatar: [],
      avatarUrl: '',
      store: '',
      showStoreSelect: false,
      storeOptions: ['店铺1:奥达汽车服务有限公司', '店铺2:奥达汽车维修中心'],
      expertiseOptions: ["发动机维护", "底盘维修", "刹车系统", "车身修复", "电气系统", "常规保养"],
      expertise: [],
      errors: {},
      showPopup: false,
      countdown: 5,
      interval: null,
    };
  },
  methods: {
    goback(){
      this.$router.push('/register');
    },
    verifyCode() {
      if (this.verificationCode === '6674564jiaoyan') {
        this.isVerified = true;
        this.$toast.success('校验通过');
      } else {
        this.$toast.fail('校验码不正确');
      }
    },
    async getOSSCredentials() {
      try {
        const response = await instance.get('/api/get-oss-signature');
        return response.data;
      } catch (error) {
        console.error('获取OSS签名失败:', error);
        return null;
      }
    },
    async onAfterRead(file) {
      const credentials = await this.getOSSCredentials();
      if (credentials) {
        const client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: credentials.accessKeyId,
          accessKeySecret: credentials.accessKeySecret,
          stsToken: credentials.securityToken,
          bucket: 'aodaqixiu',
        });
        try {
          const fileName = `static/${Date.now()}-${file.file.name}`;
          const result = await client.put(fileName, file.file);
          this.avatarUrl = result.url;
          this.$toast.success('头像上传成功');
        } catch (error) {
          this.$toast.fail('上传失败，请重新尝试');
        }
      }
    },
    selectExpertise(expertise) {
    const index = this.expertise.indexOf(expertise);
    if (index === -1) {
      if (this.expertise.length < 2) {
        this.expertise.push(expertise);
      } else {
        this.$toast.fail('最多只能选择两个擅长领域');
      }
    } else {
      this.expertise.splice(index, 1);  // 取消选择
    }
  },
    onStoreConfirm(store) {
      this.store = store;
      this.showStoreSelect = false;
    },
    openPopup() {
      if (this.validate()) {
        this.showPopup = true;
        this.startCountdown();
      }
    },
    startCountdown() {
      this.countdown = 5;
      this.interval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    validate() {
      this.errors = {};
      if (!this.name) this.errors.name = '请输入姓名';
      if (!/^1[3456789]\d{9}$/.test(this.phone)) this.errors.phone = '手机号格式不正确';
      if (this.phone !== this.phoneConfirm) this.errors.phoneConfirm = '两次输入的手机号不一致';
      if (!this.password || this.password.length < 6) this.errors.password = '密码长度不能少于6位';
      if (this.password !== this.passwordConfirm) this.errors.passwordConfirm = '两次输入的密码不一致';
      if (!this.store) this.errors.store = '请选择归属店铺';
      if (!this.expertise.length) this.errors.expertise = '请选择擅长领域';
      return Object.keys(this.errors).length === 0;
    },
    submit() {

      this.showPopup = false;
      if (this.validate()) {
    const hashedPassword = CryptoJS.MD5(this.password).toString(); // MD5加密密码
    const expertiseString = this.expertise.join(', ').replace(/, /g, ' / ');
    const userData = {
      name: this.name,
      phone: this.phone,
      password: hashedPassword,
      shop_id: this.store === '店铺1:奥达汽车维修总店' ? 1 : 2,  // 将店铺转换为相应的ID
      worker_image: this.avatarUrl,  // 上传后的头像URL
      worker_field: expertiseString  // 擅长领域
    };

    instance.post('/api/register_worker', userData)
      .then(response => {
        this.$toast.success('注册成功');
        this.$router.push('login');
        console.log(response);
        // 处理注册成功后的操作...
      })
      .catch(error => {
        this.$toast.fail('注册失败，请重试');
        console.error(error);
      });
  }

    }
  }
};
</script>

<style scoped>
.container {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.title {
  font-size: 24px;
  color: #ec5b56;
  text-align: center;
  margin-bottom: 20px;
}

.custom-uploader .van-uploader__wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.avatar-preview {
  text-align: center;
  padding: 10px 0;
}

.avatar-img {
  width: 100px;
  height: 130px;
  object-fit: cover; /* 证件照无圆角 */
}

.tags-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.expertise-tag {
  padding: 5px 10px;
  background-color: #fff;
  color: #ec5b56;
  border: 1px solid #ec5b56;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.expertise-tag.selected {
  background-color: #ec5b56;
  color: #fff;
}

.popup-content {
  padding: 20px;

  margin: 0 auto;
}
.van-cell__title{
  width: 70%!important;
  text-align: left!important;
}
.warning-text {
  font-size: 14px;
  color: #ec5b56;
  text-align: center;
  margin: 15px 0;
}

.submit-btn {
  margin-top: 20px;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
