<template>
  <div class="complex-page">
    <van-popup v-model="showPopup" :style="{height:'95%',  borderRadius: '20px 20px 0 0px' }" position="bottom">
      <div v-if="isNormal === 'unchoosed'"
           style="background-color: #f9f9f9; border: 1px solid #ec5b56; border-radius: 16px; padding: 32px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; gap: 20px;">
        <!-- 提示部分，带有 van-icon -->
        <div style="display: flex; align-items: center; justify-content: center;">
          <van-icon color="#ec5b56" name="warning-o" size="30" style="margin-right: 12px;"></van-icon>
          <p style="color: #ec5b56; font-size: 24px; font-weight: bold; margin: 0;">
            检测到您使用了大字号模式
          </p>
        </div>

        <!-- 详细提示信息 -->
        <div>
          <p style="color: rgba(51,51,51,0.8); font-size: 16px; line-height: 1.6; margin: 0;">
            这可能会导致页面显示不全或布局异常。
          </p>
          <p style="color: rgba(51,51,51,0.8); font-size: 16px; margin-top: 0px;">
            为保证页面浏览体验，请选择合适的显示模式。
          </p>
        </div>

        <!-- 按钮部分 -->
        <div style="display: flex; flex-direction: column; gap: 12px; width: 100%;">
          <van-button block color="#ec5b56" round type="primary" @click="xuanzebiaozhun">标准模式</van-button>
          <van-button block color="#ec5b56" round type="primary" @click="xuanzedazihao">大字号模式</van-button>
        </div>
      </div>
     <!-- 大字号模式下的店铺列表 -->
<div v-if="isNormal === false" style="padding: 16px; height: 100%; display: flex; flex-direction: column; align-items: center;">

  <!-- 店铺列表，宽度设置为 90% -->
  <div style="flex-grow: 1; display: flex; flex-direction: column; align-items: center;">
    <div
      v-for="shop in shops"
      :key="shop.id"
      @click="selectShop(shop)"
      :style="{
        backgroundColor: selectedShop && selectedShop.id === shop.id ? '#ffecec' : '#ffffff',
        borderRadius: '12px',
        padding: '20px',
        marginBottom: '16px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        width: '90%',  // 设置卡片的宽度为 90%
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }"
    >
      <!-- 店铺名称 -->
      <p style="font-size: 18px; color: #333; font-weight: bold; margin: 0;">{{ shop.name }}</p>

      <!-- 店铺地址 -->
      <p style="font-size: 14px; color: #666; margin: 0;">{{ shop.address }}</p>

      <!-- 店铺电话（可点击拨号） -->
      <a
        :href="'tel:' + shop.phone"
        style="font-size: 14px; color: #ec5b56; text-decoration: none; margin: 0;"
      >
        电话: {{ shop.phone ? shop.phone : '未提供' }}
      </a>

      <!-- 店铺评分 -->
      <p style="font-size: 14px; color: #999; margin: 0;">评分: {{ shop.rating }}</p>
    </div>
    <div style="width: 100%; margin-top: 20px; display: flex; flex-direction: column; gap: 12px;">
    <!-- 确认按钮，只有选择店铺后才可点击 -->
    <van-button
      color="#eb5c56"
      round
      type="primary"
      block
      :disabled="!selectedShop"
      @click="confirmSelection"
    >
      确认选择
    </van-button>

    <!-- 返回按钮 -->
    <van-button
      color="#eb5c56"
      round
      plain
      block
      @click="returnToModeSelection"
    >
      返回
    </van-button>
  </div>
  </div>

  <!-- 按钮部分，随内容布局，不固定底部 -->

</div>



      <div v-if="isNormal===true" class="popup-content">

        <div class="original-content">
          <h2 class="popup-title">选择附近的店铺</h2>
          <div v-for="shop in sortedShops" :key="shop.id" class="shop-item-container" @click="selectShop(shop)">
            <div :class="{ selected: selectedShop && selectedShop.id === shop.id }" class="shop-item">
              <div class="map-container">
                <div :id="'map' + shop.id" class="map"></div>
              </div>
              <div class="details">
                <h3 class="shop-name">{{ shop.name }}</h3>
                <p class="shop-address">{{ shop.address }}</p>
                <div class="contact-info">
                  <!--                <p class="shop-phone">{{ shop.phone }}</p>-->
                  <div class="icons">
                    <i class="iconfont icon-phone"></i>
                    <i class="iconfont icon-map"></i>
                    <i class="iconfont icon-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <van-button :disabled="!selectedShop" block class="confirm-button" type="primary" @click="confirmSelection">
              确认选择
            </van-button>
            <van-button round block color="#eb5c56" plain style="margin-top: 20px;color: white" @click="returnToModeSelection">
              返回
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="content">
      <div v-if="!selectedShop" class="overlay">
        <van-empty description="暂未选择店铺" style="margin-top: 20vh"/>
      </div>
      <div v-else>
        <div v-if="currentTab === 'home'" class="">
          <div v-if="currentTab === 'home'" class="selected-shop-info">
            <div class="shop-info-container">
              <div class="left-section">
                <div class="shop-details">
                  <h2 class="shop-title">已选择的店铺</h2>
                  <p class="shop-detail">名称: {{ selectedShop.name }}</p>
                  <p class="shop-detail">地址: {{ selectedShop.address }}</p>
                  <p class="shop-detail" style="color:#ec5b56;">电话: {{ selectedShop.phone }}</p>
                  <p class="shop-detail">营业时间: {{ selectedShop.hours }}</p>
                </div>
                <van-button class="navigate-button" type="info" @click="navigateToShop(selectedShop)">导航去这里
                </van-button>
              </div>
              <div class="right-section">
                <div class="map-container2">
                  <div id="selectedShopMap" class="map"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentTab === 'home'" style="width: 100%">
          <van-button block color="#ec5b56" plain
                      style="margin: 2vh auto 0;width: 95%;border-radius:10px" @click="fanhuixuanzedianpu">
            返回选择店铺
          </van-button>
          <van-button block color="#ec5b56" style="margin: 2vh auto 0;width: 95%;border-radius:10px"
                      type="primary" @click="zhinengxuanze">
            智能选择
          </van-button>

        </div>
        <div v-if="currentTab === 'home'" class="selected-shop-info" style="margin-top: 2vh">
          <div v-for="user in users" :key="user.worker_id" class="user-item-container">
            <div @click="getWorkerDetail(user)">
              <UserInfoItem

                  :name="user.worker_name"
                  :photo="user.worker_image"
                  :progress="user.process"
                  :progressColor="'#ec5b56'"
                  :rate="user.worker_rate"
                  :tags="user.worker_field"
                  :waitTime="user.estimated_wait_time"
              />
            </div>

            <!-- 订单统计信息展示 -->
            <div class="order-summary">
              <p>进行中订单: <span class="order-count in-progress-count">{{
                  user.orders.filter(order => order.status === 2).length
                }}</span></p>
              <p>未完成订单: <span class="order-count not-completed-count">{{
                  user.orders.filter(order => order.status === 1).length
                }}</span></p>
            </div>

            <!-- 订单信息展示 -->
            <div v-if="user.orders && user.orders.length > 0" class="orders-section">
              <!--      <h4 class="orders-title">订单信息</h4>-->
              <div v-for="order in user.orders" :key="order.id"
                   :class="['order-item', order.status === 2 ? 'in-progress' : 'not-completed']">
                <p class="order-status"><strong>状态:</strong> <span
                    :class="order.status === 2 ? 'in-progress-status' : 'not-completed-status'">{{
                    order.status === 2 ? '进行中' : '未处理'
                  }}</span></p>

                <p class="order-id"><strong>服务类型:</strong> <span class="order-info">{{ order.service_type }}</span>
                </p>
                <p class="order-desc"><strong> 检查结果:</strong> <span class="order-info">{{
                    order.checeked_type
                  }}</span></p>
                <p class="order-desc"><strong>预计完成时间:</strong> <span
                    class="order-info">{{ order.yujiwanchengshijian }}</span></p>
              </div>
            </div>
          </div>
        </div>


        <div v-if="currentTab === 'mine'" class="user-info">
          <div class="user-profile">
            <img :src="user.photo" alt="头像" class="user-avatar">
            <div class="user-details">
              <p class="user-name">昵称: {{ user.name }}</p>
              <p class="user-id">手机号: {{ user.id }}</p>
            </div>
          </div>
          <div
              style="padding: 10px; box-shadow: 5px 5px 10px rgba(102,102,102,0.48); border-radius: 10px; background-color: #fff;">
            <!-- 会员码标题 -->
            <h1 style="font-size: 20px; color: #ec5b56; margin-bottom: 20px; font-weight: bold; text-align: center;">
              会员码</h1>

            <!-- 条形码图 -->
            <div style="display: flex; justify-content: center; margin-bottom: 10px;">
              <img :src="barcodeSrc" alt="条形码" style="max-width: 100%;"/>
            </div>

            <!-- 会员码号码 -->
            <div
                style="font-size: 14px; color: #666; letter-spacing: 2px; padding: 10px; background-color: #f8f8f8; border-radius: 8px; margin-bottom: 20px; text-align: center;">
              <p>{{ formatWithSpaces(user.id + user.phone) }}</p>

            </div>

            <!-- 二维码 -->
            <div style="display: flex; justify-content: center; margin-bottom: 20px;">
              <img :src="qrCodeUrl" alt="二维码"
                   style="width: 150px; height: 150px; border: 3px solid #ec5b56; border-radius: 8px; box-shadow: 0 4px 12px rgba(0,0,0,0.1);"/>
            </div>

            <!-- 提示信息 -->
            <p style="font-size: 12px; color: #999; margin-bottom: 20px; text-align: center;">
              本会员码定时更新，请勿截图，以免影响正常使用
            </p>

            <!-- 支付方式 -->
            <div style="display: flex; justify-content: space-around; align-items: center; margin: 20px 0;">
              <div style="text-align: center;">
                <img alt="支付宝" src="https://s3.bmp.ovh/imgs/2024/08/16/720216a6388b8448.png"
                     style="width: 40px; height: 40px;">
                <p style="font-size: 12px; margin-top: 8px;">支付宝</p>
              </div>
              <div style="text-align: center;">
                <img alt="微信" src="https://s3.bmp.ovh/imgs/2024/08/16/834009a2fc6f1bce.png"
                     style="width: 40px; height: 40px;">
                <p style="font-size: 12px; margin-top: 8px;">微信</p>
              </div>
            </div>

            <!-- 底部信息 -->
          </div>


          <van-cell-group style="margin-top: 20px">
            <!-- 修改密码 -->
            <van-cell
                is-link
                size="large"
                title="修改密码"
                @click="goToChangePassword"
            />

            <!-- 查看订单 -->
            <van-cell
                is-link
                size="large"
                title="查看订单"
                @click="goToOrders"
            />
          </van-cell-group>
          <!--          <h3 class="orders-title">订单信息</h3>-->
          <!--          <div v-for="order in user.orders" :key="order.id" class="order-item">-->
          <!--            <p class="order-id">订单号: {{ order.id }}</p>-->
          <!--            <p class="order-product">商品: {{ order.product }}</p>-->
          <!--            <p class="order-amount">金额: {{ order.amount }}</p>-->
          <!--          </div>-->

        </div>
      </div>
    </div>
    <van-tabbar v-model="currentTab" active-color="#ee0a24">
      <van-tabbar-item icon="home-o" name="home">主页</van-tabbar-item>
      <van-tabbar-item icon="user-o" name="mine">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
/* eslint-disable */
import {loadScript} from '@/utils/loadScript';
import {Toast} from 'vant';
import UserInfoItem from '@/components/UserInfoItem.vue';
import axios from "@/axios";
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import instance from "@/axios";

export default {
  name: 'ComplexPage',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UserInfoItem
  },
  data() {
    return {
      isZoomed: false,
      user: {
        id: '123456',
        name: '用户 A',
        photo: 'https://via.placeholder.com/80',
        orders: [
          {id: '1', product: '商品 A', amount: '100元'},
          {id: '2', product: '商品 B', amount: '200元'}
        ]
      },
      users: [],
      qrCodeUrl: '',
      isNormal: 'unchoosed',
      showPopup: true,
      userLocation: null,
      AMap: null,
      barcodeSrc: "",
      selectedShop: null,
      currentTab: 'home', // 当前选中的标签
      shops: [
        {
          id: 1,
          name: '奥达汽车服务有限公司',
          address: '辽宁省铁岭市银州区柴河街南段盛峰嘉苑A区东北门西侧',
          phone: '(024)72220509',
          hours: '8:00 AM - 5:30 PM',
          rating: 4.5,
          location: {latitude: 42.225569, longitude: 123.737603}
        },
        {
          id: 2,
          name: '奥达汽车维修中心',
          address: '铁岭市铁岭县澜沧江路客运枢纽站对面',
          phone: '(024)76225509',
          hours: '8:00 AM - 5:30 PM',
          rating: 4.8,
          location: {latitude: 42.282114, longitude: 123.844272}
        }
      ],
    }
  },
  computed: {
    sortedShops() {
      if (!this.userLocation) {
        return this.shops;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shops.sort((a, b) => {
        const distanceA = this.calculateDistance(this.userLocation, a.location);
        const distanceB = this.calculateDistance(this.userLocation, b.location);
        return distanceA - distanceB;
      });
    }
  },
  methods: {
  //   returnToModeSelection() {
  //   this.isNormal = 'unchoosed';  // 回到模式选择页面
  //   this.selectedShop = null;     // 重置所选店铺
  // },
    refresh() {
      this.$router.go(0)
    },
    xuanzebiaozhun() {
      this.isNormal = true;
      localStorage.setItem('isNormal', true); // 存储选择状态
      this.showPopup = true; // 关闭模式选择弹窗
      this.fetchLocation(); // 加载位置信息
    },
    xuanzedazihao() {
      this.isNormal = false;
      localStorage.setItem('isNormal', false); // 存储选择状态
      this.showPopup = true; // 关闭模式选择弹窗
      this.fetchLocation(); // 加载位置信息
    },
    returnToModeSelection() {
  this.isNormal = 'unchoosed'; // 重置为未选择状态
      console.log(this.isNormal);
  this.selectedShop = null; // 清除选中的店铺
  localStorage.removeItem('isNormal');// 从 localStorage 中移除存储的模式选择c
      console.log(localStorage.getItem('isNormal'))

},
    // confirmSelection() {
    //   if (this.selectedShop) {
    //     this.showPopup = false; // 选择店铺后关闭弹窗
    //     this.$nextTick(async () => {
    //       await this.loadAMap(); // 确保 AMap 已加载
    //       this.initSelectedShopMap(); // 初始化店铺的地图
    //     });
    //   } else {
    //     this.$toast.fail('请先选择一个店铺');
    //   }
    // },
    generateBarcode() {
      const barcodeText = `${this.user.phone}${this.user.id}`;
      const canvas = document.createElement("canvas"); // 创建 canvas 元素
      JsBarcode(canvas, barcodeText, {
        format: "CODE128",
        width: 2,
        height: 80,
        lineColor: '#454545',
        displayValue: false,
        margin: 10, // 增加边距，使条形码看起来不那么紧凑
      });

      // 将条形码图片转换为 base64
      this.barcodeSrc = canvas.toDataURL("image/png");
    },
    formatWithSpaces(text) {
      return text.replace(/(.{3})/g, '$1 '); // 每3位插入一个空格
    },
    async generateQRCode() {
      const qrCodeValue = `${this.user.phone}${this.user.id}`;
      try {
        const qrCodeDataUrl = await QRCode.toDataURL(qrCodeValue, {
          width: 150,
          margin: 1,
          color: {
            dark: '#454545', // 主题色
            light: '#ffffff',
          },
        });
        this.qrCodeUrl = qrCodeDataUrl;
      } catch (error) {
        console.error('生成二维码失败', error);
      }
    },

    // 导航到选中的店铺
    navigateToShop(shop) {
      if (!shop || !shop.location) {
        this.$toast.fail('未找到店铺位置信息');
        return;
      }

      const destinationLat = shop.location.latitude; // 店铺纬度
      const destinationLon = shop.location.longitude; // 店铺经度
      const destinationName = encodeURIComponent(shop.name); // 店铺名称编码

      // 高德地图导航 URL Scheme
      const iosUrl = `iosamap://path?sourceApplication=yourApp&dlat=${destinationLat}&dlon=${destinationLon}&dname=${destinationName}&dev=0&t=0`;
      const androidUrl = `androidamap://navi?sourceApplication=yourApp&lat=${destinationLat}&lon=${destinationLon}&dev=0&style=2`;

      // App Store 高德地图下载链接 (iOS)
      const iosDownloadUrl = `https://apps.apple.com/cn/app/id461703208`;

      // 高德地图 Android 下载页面
      const androidDownloadUrl = `https://mobile.amap.com/download`;

      // 判断是否是微信浏览器
      if (this.isWeChat()) {
        // 跳转到 WeChat 提示页面，传递导航链接
        this.$router.push({
          path: '/wechatprompt',
          query: {
            iosUrl,
            androidUrl,
            iosDownloadUrl,
            androidDownloadUrl
          }
        });
        return;
      }

      // 检查用户设备并执行导航
      if (this.isIOS()) {
        this.openApp(iosUrl, iosDownloadUrl);
      } else {
        this.openApp(androidUrl, androidDownloadUrl);
      }
    },

    // 判断是否是 iOS 系统
    isIOS() {
      const ua = navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(ua);
    },

    // 判断是否是微信浏览器
    isWeChat() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.includes('micromessenger');
    },

    // 打开高德地图应用，如果失败则跳转到下载页面
    openApp(appUrl, fallbackUrl) {
      const start = Date.now();
      const timeout = 1500; // 1.5秒内未跳转则判断为未安装

      // 尝试打开高德地图应用
      window.location.href = appUrl;

      // 设置一个计时器来检查是否跳转成功
      setTimeout(() => {
        if (Date.now() - start < timeout + 100) {
          // 如果还在当前页面，则跳转到下载页面
          window.location.href = fallbackUrl;
        }
      }, timeout);
    },
    zhinengxuanze() {
      if (this.selectedShop && this.selectedShop.id) {
        const shopId = this.selectedShop.id;
        this.$router.push(`/worker-selection/${shopId}`);
      } else {
        console.error('选中的店铺无效或未选择店铺');
        this.$toast.fail('请先选择一个店铺');
      }

    },
    fanhuixuanzedianpu() {
      this.showPopup = true
      // this.isNormal=''
      this.selectedShop=null
    },
    goToOrders() {
      this.$router.push('/quanbudingdan');
    },
    goToChangePassword() {
      this.$router.push('/register')
    },
   async loadAMap() {
  if (!this.AMap) {
    try {
      await loadScript('https://webapi.amap.com/maps?v=1.4.15&key=409d255be35228ba2da2c79c45b52e88');
      this.AMap = window.AMap;
    } catch (error) {
      console.error('高德地图脚本加载失败:', error);
      this.$toast.fail('地图加载失败，请检查网络');
    }
  }
},
    async fetchUserInfo() {
      try {
        const response = await axios.get('/user/info');
        if (response.data.status === 'success') {
          this.user = response.data.data;
          console.log(this.user);
        } else {
          this.$toast.fail(response.data.message);
        }
      } catch (error) {
        console.error('获取用户信息失败:', error);
        this.$toast.fail('获取用户信息失败');
      }
    },
    async fetchLocation() {
      if (this.isNormal === true) {
        await this.loadAMap();
        if (this.AMap && this.AMap.plugin) {
          this.AMap.plugin('AMap.Geolocation', () => {
            const geolocation = new this.AMap.Geolocation({
              enableHighAccuracy: true,
              timeout: 30000, // 增加超时时间
              buttonPosition: 'RB',
              buttonOffset: new this.AMap.Pixel(10, 20),
              zoomToAccuracy: true
            });

            geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                this.userLocation = {
                  latitude: result.position.lat,
                  longitude: result.position.lng
                };
                this.initMaps();
              } else {
                console.error('获取定位失败:', result);
                this.userLocation = {latitude: 31.2304, longitude: 121.4737}; // 使用默认位置
                this.initMaps();
              }
            });
          });
        }
      }
      if (this.isNormal === false) {
        if (this.AMap && this.AMap.plugin) {
          this.AMap.plugin('AMap.Geolocation', () => {
            const geolocation = new this.AMap.Geolocation({
              enableHighAccuracy: true,
              timeout: 30000, // 增加超时时间
              buttonPosition: 'RB',
              buttonOffset: new this.AMap.Pixel(10, 20),
              zoomToAccuracy: true
            });

            geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                this.userLocation = {
                  latitude: result.position.lat,
                  longitude: result.position.lng
                };
                this.initMaps();
              } else {
                console.error('获取定位失败:', result);
                this.userLocation = {latitude: 31.2304, longitude: 121.4737}; // 使用默认位置
                this.initMaps();
              }
            });
          });
        }
      }
      if (this.isNormal === 'unchoosed') {
        this.$toast.fail('请先完成对应选择')
      }
    },
    checkFontScaling() {
    },
    initMaps() {
      this.sortedShops.forEach((shop) => {
        this.initMap(shop);
      });
    },
    initMap(shop) {
      const map = new this.AMap.Map('map' + shop.id, {
        zoom: 15,
        center: [shop.location.longitude, shop.location.latitude],
        mapStyle: 'amap://styles/macaron' // 单色地图样式
      });

      new this.AMap.Marker({
        position: new this.AMap.LngLat(shop.location.longitude, shop.location.latitude),
        title: shop.name,
        map: map
      });
    },
    addMetaViewport() {
      let meta = document.querySelector('meta[name="viewport"]');
      if (!meta) {
        meta = document.createElement('meta');
        meta.name = 'viewport';
        document.head.appendChild(meta);
      }
      meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    },
    // 禁用双击缩放
    preventZoom(e) {
      e.preventDefault();
    },
    // 禁用双指缩放
    preventPinchZoom(e) {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    },
    // 禁用 iOS 键盘输入放大行为
    preventKeyboardZoom(e) {
      const target = e.target;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        target.style.fontSize = '16px'; // 适当调整输入框字体大小，避免缩放
      }
    },
    calculateDistance(loc1, loc2) {
      const toRad = (value) => (value * Math.PI) / 180;
      const R = 6371;
      const dLat = toRad(loc2.latitude - loc1.latitude);
      const dLon = toRad(loc2.longitude - loc1.longitude);
      const lat1 = toRad(loc1.latitude);
      const lat2 = toRad(loc2.latitude);

      const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c;
    },
  initSelectedShopMap() {
  if (this.selectedShop && this.AMap) {
    const map = new this.AMap.Map('selectedShopMap', {
      zoom: 15,
      center: [this.selectedShop.location.longitude, this.selectedShop.location.latitude],
      mapStyle: 'amap://styles/macaron',  // 自定义地图样式
    });

    new this.AMap.Marker({
      position: new this.AMap.LngLat(this.selectedShop.location.longitude, this.selectedShop.location.latitude),
      title: this.selectedShop.name,
      map: map,
    });

    // 检查地图对象是否正确实例化
    console.log('地图实例化成功:', map);
  } else {
    console.error('AMap 未正确加载或店铺信息缺失');
  }
},
    selectShop(shop) {
      this.selectedShop = shop;
      this.fetchWorkers(shop.id);
    },

    async fetchWorkers(shopId) {
      try {
        const response = await instance.get(`/api/shops/${shopId}/workers`);
        if (response.data.status === 'success') {
          this.users = response.data.data;
          // 假设 workers 数据中已经包含订单信息
          this.users.forEach(user => {
            user.orders = user.orders || [];  // 确保有订单数组
          });
        } else {
          this.$toast.fail(response.data.message);
        }
      } catch (error) {
        console.error('获取技师信息失败:', error);
        this.$toast.fail('获取技师信息失败');
      }
    },
    confirmSelection() {
  if (this.selectedShop) {
    this.showPopup = false;
    this.$nextTick(async () => {
      await this.loadAMap(); // 确保 AMap 已加载
      this.initSelectedShopMap(); // 初始化右侧地图
    });
  } else {
    this.$toast.fail('请先选择一个店铺');
  }
},
    getWorkerDetail(user) {
      this.$router.push({path: '/yuyuexiangqing', query: {user_id: user.worker_id}});
    }
  },
  watch: {
   currentTab(newVal) {
    if (newVal === 'home') {
      this.$nextTick(async () => {
        await this.loadAMap();  // 确保地图 API 加载完成
        this.initSelectedShopMap();
      });
    }
  },
  sortedShops() {
    this.sortedShops.forEach((shop) => {
      this.$nextTick(async () => {
        await this.loadAMap();
        this.initMap(shop); // 初始化每个店铺的地图
      });
    });
  }
  },

  async mounted() {
   const savedIsNormal = localStorage.getItem('isNormal');
    if (savedIsNormal !== null) {
      // 如果已经选择过模式
      this.isNormal = savedIsNormal === 'true'; // 获取布尔值
      this.fetchLocation(); // 加载位置信息
    } else {
      // 如果没有选择过模式，显示 Popup
      this.showPopup = true;
    }
    // 动态添加 viewport meta 标签
    this.addMetaViewport();

    // 禁用双击缩放
    document.addEventListener('dblclick', this.preventZoom);

    // 禁用双指缩放
    document.addEventListener('touchmove', this.preventPinchZoom, {passive: false});

    // 禁用键盘输入放大
    document.addEventListener('touchstart', this.preventKeyboardZoom, {passive: false});

    await this.fetchUserInfo();

    await this.fetchLocation();
    console.log(this.user)
    this.generateBarcode();
    this.generateQRCode();
    this.checkFontScaling();
    window.addEventListener('resize', this.checkFontScaling);


  },
  beforeDestroy() {
    document.removeEventListener('dblclick', this.preventZoom);
    document.removeEventListener('touchmove', this.preventPinchZoom);
    document.removeEventListener('touchstart', this.preventKeyboardZoom);
  }
}
</script>

<style scoped>
@import url('//at.alicdn.com/t/font_8d5l8fzk5b87iudi.css'); /* 引入iconfont图标 */

.complex-page {
  padding: 10px; /* 减小 padding 大小 */
  padding-bottom: 50px; /* 给底部导航栏留出空间 */
}

.popup-content {
  height: 100%;
  padding: 10px;
  border-radius: 20px 20px 0 0;
  position: relative; /* 相对定位 */
}

.original-content {
  position: absolute; /* 绝对定位 */
  top: 50%;
  left: 50%;
  width: 90%; /* 保证宽度为100% */
  transform: translate(-50%, -50%); /* 将元素的中心点移到容器的中心 */
  /* 其他样式保持不变 */
}


.popup-title {
  margin-top: 50px;
  font-size: 18px; /* 缩小字体大小 */
  color: #ec5b56;
  text-align: center;
  margin-bottom: 10px; /* 调整标题的底部间距，使内容更加紧凑 */
  font-weight: bold;
}

.shop-item-container {
  display: flex;
  justify-content: center;
  margin-bottom: 8px; /* 调整项目容器的底部间距 */
}

.shop-item {
  width: 95%; /* 增加宽度 */
  max-width: 380px; /* 减小最大宽度 */
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px; /* 减小圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.shop-item.selected {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid #ec5b56; /* 减小边框宽度 */
}

.shop-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.map-container {
  width: 100%;
  height: 10px;
  padding-bottom: 60%; /* 设置地图部分占比 */
  position: relative;
}

.map-container2 {
  width: 100%;
  height: 80px; /* 减小高度 */
  padding-bottom: 60%; /* 设置地图部分占比 */
  position: relative;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0; /* 减小圆角 */
  overflow: hidden;
}

.details {
  padding: 6px 8px; /* 调整详细信息部分的填充，使其更加紧凑 */
  height: auto; /* 设置文字信息部分占比 */
  flex: 1;
}

.shop-name {
  margin: 0;
  font-size: 14px; /* 调整店铺名称的字体大小 */
  color: #333;
  font-weight: bold;
}

.shop-address,
.shop-phone {
  margin: 3px 0; /* 调整地址和电话的间距 */
  color: #666;
}

.van-progress__pivot {
  display: none !important;
  min-width: 0em !important;
}

.shop-address {
  font-size: 12px; /* 调整地址的字体大小 */
}

.shop-phone {
  font-size: 12px; /* 调整电话的字体大小 */
  color: #ec5b56;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons {
  display: flex;
  gap: 6px; /* 调整图标之间的间距 */
}

.iconfont {
  font-size: 16px; /* 调整图标的大小 */
  color: #ec5b56;
}

.popup-footer {
  text-align: center;
  padding: 8px; /* 减小 padding 大小 */
  font-size: 12px; /* 缩小字体大小 */
  color: #666;
  border-radius: 0 0 20px 20px;
}

.popup-footer p {
  margin-bottom: 8px; /* 减小 margin 大小 */
}

.confirm-button {
  background-color: #ec5b56;
  border-color: #ec5b56;
  border-radius: 20px;
}

.content {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.overlay-text {
  font-size: 20px;
  color: #666;
}

.van-tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;
}

.selected-shop-info {
  background-color: #fff;
  border-radius: 8px; /* 减小圆角 */
  padding: 10px; /* 减小 padding 大小 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.shop-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* 修改为垂直居中 */
}

.left-section {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-section {
  width: 50%;
}

.map {
  width: 100%;
  height: 100%;
}

.shop-title {
  font-size: 18px; /* 缩小字体大小 */
  color: #ec5b56;
  font-weight: bold;
  margin-bottom: 5px;
}

.shop-detail {
  font-size: 14px; /* 缩小字体大小 */
  color: #333;
  margin: 5px 0;
}

.navigate-button {
  background-color: #ec5b56;
  border-color: #ec5b56;
  border-radius: 10px;
  color: #fff;
  font-size: 12px; /* 缩小字体大小 */
  margin-top: 10px;
  align-self: flex-start;
}

.user-info {
  background-color: #fff;
  border-radius: 8px; /* 减小圆角 */
  padding: 15px; /* 减小 padding 大小 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.user-title {
  font-size: 18px;
  color: #ec5b56;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
}

.user-avatar {
  width: 60px; /* 缩小头像大小 */
  height: 60px; /* 缩小头像大小 */
  border-radius: 50%;
  margin-right: 15px; /* 减小 margin 大小 */
}

.user-details {
  flex-grow: 1;
}

.user-name {
  font-size: 18px; /* 缩小字体大小 */
  color: #ec5b56;
  font-weight: bold;
  margin: 5px 0;
}

.user-id {
  font-size: 14px; /* 缩小字体大小 */
  color: rgba(0, 0, 0, 0.53);
  font-weight: normal;
  margin: 5px 0;
}

.change-password-button {
  background-color: #ec5b56;
  border-color: #ec5b56;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 15px; /* 减小 margin 大小 */
  width: 100%;
}

.order-info {
  text-align: right;
}

.user-item-container {
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.order-summary {
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.order-summary p {
  margin: 0;
  font-size: 16px;
}

.order-count {
  font-size: 20px;
}

.in-progress-count {
  color: rgba(236, 91, 86, 0.8); /* 进行中订单数量颜色，主题色不透明 */
}

.not-completed-count {
  color: rgba(236, 91, 86, 0.3); /* 未完成订单数量颜色，主题色透明度较高 */
}

.orders-section {
  margin-top: 10px;
}

.orders-title {
  margin-bottom: 8px;
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.order-item {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.order-item.in-progress {
  background-color: rgba(236, 91, 86, 0.1); /* 进行中订单背景色，使用主题色透明度较低 */
  border-left: 4px solid rgba(236, 91, 86, 0.8); /* 主题色不透明 */
}

.order-item.not-completed {
  background-color: rgba(236, 91, 86, 0.05); /* 未完成订单背景色，使用主题色更高透明度 */
  border-left: 4px solid rgba(236, 91, 86, 0.5); /* 主题色透明度较高 */
}

.order-desc {
  margin: 4px 0;
  font-size: 15px;
}

.order-id, .order-status {
  margin: 4px 0;
  font-size: 13px;
}

.order-info {
  font-weight: bold;
  color: #333;
}

.in-progress-status {
  color: rgba(236, 91, 86, 0.75);
  font-weight: bold;
}

.not-completed-status {
  color: rgba(236, 91, 86, 0.5);
  font-weight: bold;
}


</style>
